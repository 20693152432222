import React, {useEffect} from 'react';
import backgroundImage from '../../assets/E.png';
import { BsArrowRightCircle } from 'react-icons/bs';
import AOS from 'aos';
import { Link } from 'react-router-dom';
const Section = () => {
  //const backgroundImage = "https://elasticbeanstalk-us-east-1-226352817393.s3.amazonaws.com/promitto/E.png";
  useEffect(() => {
    AOS.init({ duration: 2000});
},[]);
  const sectionStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
  };
return (

    <section class="sm:mx-4 bg-cover bg-opacity-50 dark:bg-gray-900 border rounded-lg border-gray-200" style={sectionStyle}>
    <div class="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
        <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-xl text-weight-500 font-lufga leading-none tracking-tight md:text-5xl sm:text-2xl xl:text-6xl dark:text-white text-gray-100" data-aos="fade-left">
            Find the Finest <br /> Residential Properties..</h1>
            <p class="max-w-2xl mb-6 font-weight-400 text-white font-lufga lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400" data-aos="fade-right">
            Whether you're searching for a luxurious villa, a cozy family home, or a trendy urban apartment, we have the perfect option for you
            </p>
           <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">

           <Link to='/register' class="inline-flex items-center font-lufga bg-[#F2B807] justify-center w-full px-5 py-3 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  focus:ring-4 focus:ring-gray-100 dark:text-white" data-aos="fade-up">
                       Enroll Now <BsArrowRightCircle className='text-black font-bold ml-2' />
                    </Link>
            </div>
        </div>
    </div>
</section>



  );
}

export default Section;
