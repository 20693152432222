import React, {useState, useEffect} from "react";
import axios from "axios";
import AdminHeader from "./Admin/Components/AdminHeader";
import Aside from "./Admin/Components/Aside";
import Cookies from "js-cookie";
import jwtDecode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../config";
import {AiOutlineCheckSquare} from 'react-icons/ai';
import profileImage from '../assets/about-us/Joseph.png';
import { Link } from "react-router-dom";
import {BsEye} from 'react-icons/bs';
import {
  Modal,
  Ripple,
  initTE,
} from "tw-elements";
import io from 'socket.io-client';
const Dashboard = () => {
  initTE({ Modal, Ripple });
   const [clientId, setClientId] = useState('');
   const [manager, setManger] = useState('');
   const [username, setUsername] = useState('');
   //const token = Cookies.get('token');
   const [role, setRole] = useState('');
   const navigate = useNavigate();
   const [projectInfo, setProjectInfo] = useState([]);
   const [numberOfPhotos, setnumberOfPhotos] = useState([]);
   const [BQFiles, setBQFiles] = useState([]);
   const [kyc, setkyc] = useState([]);
   const [drawings, setDrawings] = useState([]);
   const [loanStatus, setLoanStatus] = useState([]);
   const [constructionUpdate, setconstructionUpdate] = useState([]);
   const [groundBreaking, setgroundBreaking] = useState([]);
   const [financialInfo, setfinancialInfo] = useState([]);
   const [siteVisit, setSiteVisit] = useState([]);
   const [profileInfo, setProfileInfo] = useState([]);

  const loadProfileData = async (id) => {
    fetch(`${BASE_URL}/api/getUserProfile/${id}`)
            .then((response) => response.json())
            .then((data) => {
              if (data.Status === "Success") {
                console.log(data.Profile);
                setProfileInfo(data.Profile[0]);
              } else {
               return;
              }
            })
            .catch((error) => console.log(error));
  }
   const loadData = async (id) => {
    fetch(`${BASE_URL}/api/getClientProjectByClientId/${id}`)
            .then((response) => response.json())
            .then((data) => {
              if (data.Status === "Success") {
                console.log(data.ProjectDetails);
                setProjectInfo(data.ProjectDetails);
                setkyc(JSON.parse(data.ProjectDetails[0].kyc));
                setLoanStatus(JSON.parse(data.ProjectDetails[0].loan_status));
                setconstructionUpdate(JSON.parse(data.ProjectDetails[0].construction_updates));
                setgroundBreaking(JSON.parse(data.ProjectDetails[0].ground_breaking));
                setfinancialInfo(JSON.parse(data.ProjectDetails[0].financial_info));
                setSiteVisit(JSON.parse(data.ProjectDetails[0].site_visit));

                if(data.ProjectDetails[0].project_images != null){
                  const ArrayData = JSON.parse(data.ProjectDetails[0].project_images);

                  setnumberOfPhotos(ArrayData.length);
                }else{
                  setnumberOfPhotos("0");
                }
                if(data.ProjectDetails[0].bq != null){
                  const ArrayData = JSON.parse(data.ProjectDetails[0].bq);

                  setBQFiles(ArrayData.length);
                }else{
                  setBQFiles("0");
                }
                if(data.ProjectDetails[0].drawings != null){
                  const ArrayData = JSON.parse(data.ProjectDetails[0].drawings);

                  setDrawings(ArrayData.length);
                }else{
                  setDrawings("0");
                }

              } else {
               return;
              }
            })
            .catch((error) => console.log(error));
   }
useEffect(() => {
  const token = localStorage.getItem("token");
   if (token) {
      const tokenPayload = jwtDecode(token);
      const id = tokenPayload.id;
      loadProfileData(id);
      setClientId(id);
       if(tokenPayload.role != "basic"){
         alert("You are not authorized to access this page")
         return;
       }else{
         const headers = {
            authorization: `Bearer ${token}`,
          };

          loadData(id);
       }

     }else{
      navigate('/login');
     }
}, [])


useEffect(() => {
  const socket = io(BASE_URL); // Replace with your Socket.io server URL

  socket.on('kycUpdated', (data) => {
    if (data) {
      console.log("Received data from the server:");
      console.log(socket.id);
      console.log(data.message);

      // Update the component state or show a notification
      //setKycUpdateReceived(true);
      // You can also store the received data in a state variable for later use.

    } else {
      console.log("Received empty data from the server.");
    }
  });

  return () => {
    socket.off('kycUpdated');
  };
}, []);

const reloadHistory =() =>{
    const token = localStorage.getItem("token");
     if (token) {
        const tokenPayload = jwtDecode(token);
        const id = tokenPayload.id;
        loadData(id);
     }
}
return (
   <section>
     <div>
       <AdminHeader />
       <div className="flex overflow-hidden bg-white pt-16">
         <Aside />
         <div
           className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
           id="sidebarBackdrop"
         ></div>
         <div
           id="main-content"
           className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
         >
           <main>
             <div className="pt-6 px-2">



               <div className="flex flex-col mt-6">
               <div className='flex items-center gap-[10px] mb-10'>
                   <div className="w-10 h-10 border-gray-100 shadow dark:border-gray-600 rounded-2xl flex items-center justify-center overflow-hidden">
                       {/* Replace the 'path/to/image.png' with the actual path to your image file */}
                       <img src={profileInfo.passportPhoto} alt="Profile Image" className="rounded-full object-cover w-full h-full" />
                   </div>
                   <div className='leading-[25px]'>
                   <h2 className='text-[18px] font-lufgaBold text-[#F2B807]'>{profileInfo.FullName}</h2>
                   <p className="text-gray-600 sm:text-[14px] dark:text-gray-400 font-lufga" data-aos="fade-up">
                   <span className="font-lufgaBold text-sm">Project Type: </span>{profileInfo.projectType}
                   </p>

                   </div>


               </div>

                <section class="bg-white dark:bg-gray-900 border-[2px] border-[#f5d77b] rounded-sm bg-gradient-to-b from-[#f9f4e6] via-[#faf9f5] to-transparent">
                <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-10 lg:px-6">
                    <div class="max-w-screen-md mb-8 text-left lg:mb-12">




                    </div>
                    <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-4 lg:space-y-0">
                        {/* <!-- KYC Card --> */}
                        <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                                Project Details
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Project information is displayed here
                              </h3>
                              <div className="max-w-screen-md mx-auto mt-2 flex items-center justify-center">
                              {kyc?.length > 0 ? (
                         <button
                         onClick={reloadHistory}
                         data-te-toggle="modal"
                         data-te-target="#exampleModalXl"
                         data-te-ripple-init
                         data-te-ripple-color="light"
                         className="flex items-center rounded-[5px] bg-[#f5d77b] px-1 py-2 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                       >
                         <BsEye className="ml-4 mr-2 text-sm" />{" "}
                         <span className="mr-8">View Info</span>
                         </button>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}

                              </div>
                            </div>
                        </div>
                        {/* <!--Modal body--> */}
                        <div
                            data-te-modal-init
                            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                            id="exampleModalXl"
                            tabindex="-1"
                            aria-labelledby="exampleModalXlLabel"
                            aria-modal="true"
                            role="dialog"
                          >
                            <div
                              data-te-modal-dialog-ref
                              class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
                            >
                              <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                                <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                  <h5
                                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                    id="exampleModalXlLabel"
                                  >
                                    Project Details
                                  </h5>
                                  <button
                                    type="button"
                                    class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    data-te-modal-dismiss
                                    aria-label="Close"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="h-6 w-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                {/* <!--Modal body--> */}
                                <div class="relative p-4">
                                {kyc?.length > 0 ? (
                                  <>
                                  <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Title</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Description</th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!kyc.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            kyc.map((kycItem, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{kycItem.description}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{kycItem.description_value}</td>


                              </tr>
                            ))
                          )}
                        </tbody>


                    </table>
                </div></>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}
                                </div>
                              </div>
                            </div>
                          </div>
                        {/* <!-- Site Visit Card --> */}
                          <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Site Visit
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Site Visit information will be updated here.
                              </h3>

                              <div className="max-w-screen-md mx-auto mt-2 flex items-center justify-center">
                              {siteVisit?.length > 0 ? (
                         <button
                         onClick={reloadHistory}
                         data-te-toggle="modal"
                         data-te-target="#sv"
                         data-te-ripple-init
                         data-te-ripple-color="light"
                         className="flex items-center rounded-[5px] bg-[#f5d77b] px-1 py-2 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                       >
                         <BsEye className="ml-4 mr-2 text-sm" />{" "}
                         <span className="mr-8">View Info</span>
                         </button>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}

                              </div>
                              <div
                            data-te-modal-init
                            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                            id="sv"
                            tabindex="-1"
                            aria-labelledby="exampleModalXlLabel"
                            aria-modal="true"
                            role="dialog"
                          >
                            <div
                              data-te-modal-dialog-ref
                              class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
                            >
                              <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                                <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                  <h5
                                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                    id="sv"
                                  >
                                    Site Visit Details
                                  </h5>
                                  <button
                                    type="button"
                                    class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    data-te-modal-dismiss
                                    aria-label="Close"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="h-6 w-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                {/* <!--Modal body--> */}
                                <div class="relative p-4">
                                {siteVisit?.length > 0 ? (
                                  <>
                                  <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Title</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Description</th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!siteVisit.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            siteVisit.map((item, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.site_visit_desc}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.site_visit_value}</td>


                              </tr>
                            ))
                          )}
                        </tbody>


                    </table>
                </div></>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                             </div>
                         {/* <!-- Drawings Card --> */}
                         <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Drawings
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Project drawings is displayed here
                              </h3>

                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              {drawings} Files Uploaded
                              </h3>
                              <div className="max-w-screen-md mx-auto text-center">
                               <Link to={`/drawings/${clientId}`}
                                className="text-sm font-lufgaBold text-[#F2B807]">
                                View Drawings Files
                              </Link>

                              </div>
                            </div>
                         </div>
                            {/* <!-- BQ Preparation Card --> */}
                            <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              BQ Preparation
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Current BQ Preparation Data
                              </h3>

                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              {BQFiles} Files Uploaded
                              </h3>
                              <div className="max-w-screen-md mx-auto text-center">
                              <Link to={`/bq-files/${clientId}`}
                                className="text-sm font-lufgaBold text-[#F2B807]">
                                View BQ Files
                              </Link>

                              </div>
                            </div>
                            </div>
                            {/* <!-- Financial Information Card --> */}
                            <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Financial Information
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Financial Information will be updated here.
                              </h3>

                              <div className="max-w-screen-md mx-auto mt-2 flex items-center justify-center">
                              {financialInfo?.length > 0 ? (
                         <button
                         onClick={reloadHistory}
                         data-te-toggle="modal"
                         data-te-target="#fi"
                         data-te-ripple-init
                         data-te-ripple-color="light"
                         className="flex items-center rounded-[5px] bg-[#f5d77b] px-1 py-2 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                       >
                         <BsEye className="ml-4 mr-2 text-sm" />{" "}
                         <span className="mr-8">View Info</span>
                         </button>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}

                              </div>
                              <div
                            data-te-modal-init
                            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                            id="fi"
                            tabindex="-1"
                            aria-labelledby="exampleModalXlLabel"
                            aria-modal="true"
                            role="dialog"
                          >
                            <div
                              data-te-modal-dialog-ref
                              class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
                            >
                              <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                                <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                  <h5
                                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                    id="sv"
                                  >
                                    Financial Info Details
                                  </h5>
                                  <button
                                    type="button"
                                    class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    data-te-modal-dismiss
                                    aria-label="Close"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="h-6 w-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                {/* <!--Modal body--> */}
                                <div class="relative p-4">
                                {financialInfo?.length > 0 ? (
                                  <>
                                  <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Title</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Description</th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!financialInfo.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            financialInfo.map((item, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.financial_desc}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.financial_value}</td>


                              </tr>
                            ))
                          )}
                        </tbody>


                    </table>
                </div></>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                             </div>
                            {/* <!-- Ground Breaking Card --> */}
                            <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Ground Breaking
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Ground Breaking updates will be displayed here.
                              </h3>

                              <div className="max-w-screen-md mx-auto mt-2 flex items-center justify-center">
                              {groundBreaking?.length > 0 ? (
                         <button
                         onClick={reloadHistory}
                         data-te-toggle="modal"
                         data-te-target="#gb"
                         data-te-ripple-init
                         data-te-ripple-color="light"
                         className="flex items-center rounded-[5px] bg-[#f5d77b] px-1 py-2 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                       >
                         <BsEye className="ml-4 mr-2 text-sm" />{" "}
                         <span className="mr-8">View Info</span>
                         </button>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}

                              </div>
                              <div
                            data-te-modal-init
                            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                            id="gb"
                            tabindex="-1"
                            aria-labelledby="exampleModalXlLabel"
                            aria-modal="true"
                            role="dialog"
                          >
                            <div
                              data-te-modal-dialog-ref
                              class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
                            >
                              <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                                <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                  <h5
                                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                    id="sv"
                                  >
                                    Ground Breaking Details
                                  </h5>
                                  <button
                                    type="button"
                                    class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    data-te-modal-dismiss
                                    aria-label="Close"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="h-6 w-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                {/* <!--Modal body--> */}
                                <div class="relative p-4">
                                {groundBreaking?.length > 0 ? (
                                  <>
                                  <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Title</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Description</th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!groundBreaking.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            groundBreaking.map((item, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.ground_desc}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.ground_value}</td>


                              </tr>
                            ))
                          )}
                        </tbody>


                    </table>
                </div></>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                            </div>
                            {/* <!-- Construction Updates --> */}
                           <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Construction Updates
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Construction Updates will be updated here.
                              </h3>

                              <div className="max-w-screen-md mx-auto mt-2 flex items-center justify-center">
                              {constructionUpdate?.length > 0 ? (
                         <button
                         data-te-toggle="modal"
                         data-te-target="#cu"
                         data-te-ripple-init
                         data-te-ripple-color="light"
                         className="flex items-center rounded-[5px] bg-[#f5d77b] px-1 py-2 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                       >
                         <BsEye className="ml-4 mr-2 text-sm" />{" "}
                         <span className="mr-8">View Info</span>
                         </button>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}

                              </div>
                              <div
                            data-te-modal-init
                            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                            id="cu"
                            tabindex="-1"
                            aria-labelledby="exampleModalXlLabel"
                            aria-modal="true"
                            role="dialog"
                          >
                            <div
                              data-te-modal-dialog-ref
                              class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
                            >
                              <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                                <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                  <h5
                                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                    id="sv"
                                  >
                                    Construction Updates
                                  </h5>
                                  <button
                                    type="button"
                                    class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    data-te-modal-dismiss
                                    aria-label="Close"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="h-6 w-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                {/* <!--Modal body--> */}
                                <div class="relative p-4">
                                {constructionUpdate?.length > 0 ? (
                                  <>
                                  <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Title</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Description</th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!constructionUpdate.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            constructionUpdate.map((item, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.construction_desc}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.construction_value}</td>


                              </tr>
                            ))
                          )}
                        </tbody>


                    </table>
                </div></>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                            </div>

                            {/* <!-- Photos Card --> */}
                            <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Project Photos
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Project photos uploaded updates appear here.
                              </h3>

                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              {numberOfPhotos} Files Uploaded
                              </h3>
                              <div className="max-w-screen-md mx-auto text-center">
                              <Link to={`/project-images/${clientId}`}
                                className="text-sm font-lufgaBold text-[#F2B807]">
                                View Uploaded Files
                              </Link>

                              </div>
                            </div>
                            </div>

                            {/* <!-- Loan Card --> */}
                             <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Loan Application Status
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                              Current loan status will be updated here.
                              </h3>


                              <div className="max-w-screen-md mx-auto mt-2 flex items-center justify-center">
                              {loanStatus?.length > 0 ? (
                         <button
                         onClick={reloadHistory}
                         data-te-toggle="modal"
                         data-te-target="#las"
                         data-te-ripple-init
                         data-te-ripple-color="light"
                         className="flex items-center rounded-[5px] bg-[#f5d77b] px-1 py-2 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                       >
                         <BsEye className="ml-4 mr-2 text-sm" />{" "}
                         <span className="mr-8">View Info</span>
                         </button>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}

                              </div>
                              <div
                            data-te-modal-init
                            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                            id="las"
                            tabindex="-1"
                            aria-labelledby="exampleModalXlLabel"
                            aria-modal="true"
                            role="dialog"
                          >
                            <div
                              data-te-modal-dialog-ref
                              class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
                            >
                              <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                                <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                  <h5
                                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                    id="sv"
                                  >
                                    Loan Application Status Details
                                  </h5>
                                  <button
                                    type="button"
                                    class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    data-te-modal-dismiss
                                    aria-label="Close"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="h-6 w-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                {/* <!--Modal body--> */}
                                <div class="relative p-4">
                                {loanStatus?.length > 0 ? (
                                  <>
                                  <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Title</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Description</th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!loanStatus.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            loanStatus.map((item, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.loan_desc}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{item.loan_value}</td>


                              </tr>
                            ))
                          )}
                        </tbody>


                    </table>
                </div></>
                        ) : (
                        <p className='mt-4 font-lufga'>No data available.</p>
                        )}
                                </div>
                              </div>
                            </div>
                          </div>
                            </div>
                             </div>




                    </div>
                </div>
              </section>


               </div>
             </div>
           </main>
           <p className="text-center text-sm text-gray-500 my-10">
             &copy; 2023{" "}
             <a
               href="https://promitto.com"
               className="hover:underline"
               target="_blank"
             >
               Promitto
             </a>
             . All rights reserved.
           </p>
         </div>
       </div>
       <script async defer src="https://buttons.github.io/buttons.js"></script>
       <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
     </div>
   </section>
 );
}

export default Dashboard;