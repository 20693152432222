import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../config';
import AdminHeader from '../Components/AdminHeader';
import Aside from '../Components/Aside';
import { Document, Page } from 'react-pdf';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {BiSolidDownload} from 'react-icons/bi';

const DrawingsFilesPage = () => {
  const { id } = useParams();
  const [projectInfo, setProjectInfo] = useState([]);
  const [projectImages, setProjectImages] = useState([]);
  const [projectPDFs, setProjectPDFs] = useState([]);

  const openImageInNewTab =(imageUrl) => {
    // Use window.open() to open the image in a new tab
    window.open(imageUrl, '_blank');
  }
  const docs = [
    { uri: "https://url-to-my-pdf.pdf" },
  ];


  const getProjectDetails = () => {
    fetch(`${BASE_URL}/api/getClientProjectByClientId/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === 'Success') {
          if (data.ProjectDetails[0].drawings != null) {
            setProjectInfo(data.ProjectDetails);
            const files = JSON.parse(data.ProjectDetails[0].drawings);
            const pdfArray = files.filter(item => item.endsWith('.pdf'));
            const nonPdfArray = files.filter(item => !item.endsWith('.pdf'));
            setProjectImages(nonPdfArray);
            setProjectPDFs(pdfArray);


            console.log(pdfArray);
            console.log(nonPdfArray);

          }
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    getProjectDetails();

    const intervalId = setInterval(getProjectDetails, 2000);

    // Clean up the interval when the component unmounts.
    return () => clearInterval(intervalId);
  }, [id]);
const plugin = defaultLayoutPlugin();
  return (
    <section>
      <div>
        <AdminHeader />
        <div className="flex overflow-hidden bg-white pt-16">
          <Aside />
          <div
            className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
            id="sidebarBackdrop"
          ></div>
          <div
            id="main-content"
            className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
          >
            <main>

            <div className="pt-6 px-4">


<div className="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
   <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
      <div className="flex items-center justify-between mb-4">
         <div className="flex-shrink-0">
            <h3 className="text-xl font-bold text-gray-900 mb-2">Drawings Images</h3>
         </div>

      </div>
      <hr/>
      <div id="main-chart">
      <div className="space-y-2 lg:grid lg:grid-cols-4 sm:gap-3 xl:gap-5 lg:space-y-0">
        {projectImages.length > 0 ? (
          projectImages.map((name, index) => (
            <div key={index} className="flex relative overflow-hidden rounded-[20px]">
              <img
                src={`${BASE_URL}/drawings/${name}`}
                alt={name}
                className="w-full"
                onClick={() => openImageInNewTab(`${BASE_URL}/drawings/${name}`)}
              />
            </div>
          ))
        ) : (
          <p className="mt-8 font-lufgaBold">No Image Files available.</p>
        )}
      </div>
      <div className='h-[800px] w-[600px]'>
      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Viewer fileUrl={`${BASE_URL}/drawings/invoice_68.pdf`}  plugins={[plugin]}/>
      </Worker> */}
      </div>
      </div>
   </div>
   <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
      <div className="mb-4 flex items-center justify-between">
         <div>
            <h3 className="text-xl font-bold text-gray-900 mb-2">PDF Files</h3>
             </div>

      </div>
      <hr/>
      <div className="flex flex-col mt-2">
      <div className="mt-4">
      <table className="min-w-full divide-y divide-gray-200">
                                    <tbody className="bg-white">
                                    {projectPDFs.length > 0 ? (
          projectPDFs.map((name, index) => (
            <tr key={index} className="bg-gray-50">
                                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900 rounded-lg rounded-left">
                                          {name}
                                          </td>
                                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                          <a
  href={`${BASE_URL}/drawings/${name}`}
  target="_blank"
  rel="noopener noreferrer"
  className="flex items-center"
>
  <button
    className="flex items-center rounded-[5px] bg-[#F2B807] px-4 py-2 text-center text-xs font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
  >
    <BiSolidDownload className="mr-2 text-lg" />Download
  </button>
</a>
                                          </td>

                                       </tr>
//             <div key={index}>
//               {/* Render PDF viewer for each PDF file */}
//               {/* <p>{name}</p> */}
//               <a
//   href={`${BASE_URL}/drawings/${name}`}
//   target="_blank"
//   rel="noopener noreferrer"
//   className="flex items-center"
// >
//   <span>{name}</span>
//   <button
//     className="flex items-center rounded-[5px] bg-[#F2B807] px-4 py-2 text-center text-xs font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
//   >
//     <BiSolidDownload className="mr-2 text-lg" />Download
//   </button>
// </a>



//             </div>
          ))
        ) : (
          <p className="mt-8 font-lufgaBold">No PDF Files available.</p>
        )}
                                    </tbody>
                                 </table>

      </div>
      </div>
   </div>
</div>
</div>

            </main>
            <p className="text-center text-sm text-gray-500 my-10">
              &copy; 2023 <a href="https://promittoltd.com" className="hover:underline" target="_blank">Promitto</a>. All rights reserved.
            </p>
          </div>
        </div>
        <script async defer src="https://buttons.github.io/buttons.js"></script>
        <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
      </div>
    </section>
  );
};

export default DrawingsFilesPage;
