import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../config";

const AboutProject = () => {

  const { id } = useParams();
  const [listing, setListing] = useState(null);
  const [listingImages, setListingImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    fetch(`${BASE_URL}/api/getListingById/${id}`)
      .then(response => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          console.log(data.Listing[0].more_images);
          setListing(data.Listing);
          if (data.Listing[0].more_images) {
            setListingImages(data.Listing[0].more_images);
            setSelectedImage(data.Listing[0].more_images[0]);
          }
        } else {
          return;
        }
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
    return (

        <main>
            <Header />
            <section class="py-12 sm:py-16 my-28">
  <div class="container mx-auto px-4">
    <nav class="flex">
      <ol role="list" class="flex items-center">
        <li class="text-left">
          <div class="-m-1">
            <a href="#" class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"> Home </a>
          </div>
        </li>

        <li class="text-left">
          <div class="flex items-center">
            <span class="mx-2 text-gray-400">/</span>
            <div class="-m-1">
              <a href="#" class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"> Listing </a>
            </div>
          </div>
        </li>


      </ol>
    </nav>

    <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
            <div className="lg:col-span-3 lg:row-end-1">
              <div className="lg:flex lg:items-start">
                <div className="lg:order-2 lg:ml-5">
                  <div className="max-w-xl overflow-hidden rounded-lg">
                    {/* main image */}
                    <img className="h-full w-full max-w-full object-cover" src={`${BASE_URL}/listings/${selectedImage}`} alt="" />
                  </div>
                </div>
                <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                  <div className="flex flex-row items-start lg:flex-col">
                  {listingImages.slice(0, 4).map((image, imageIndex) => (

                      <button
                        key={imageIndex}
                        type="button"
                        className={`flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 ${selectedImage === image ? "border-gray-900" : "border-transparent"} text-center`}
                        onClick={() => handleImageClick(image)}
                      >
                        {/* displayed images */}
                        <img
                          className="h-full w-full object-cover"
                          src={`${BASE_URL}/listings/${image}`}
                          alt=""
                        />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
  { listing ? (

listing.map((lst, index)=>(
  <><div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
    <h1 class="sm: text-2xl font-bold text-gray-900 sm:text-3xl">{lst.type}</h1>
    <p class="mt-4 font-lufga">{lst.description}</p>
    <hr />
    <p class="mt-4 font-lufga"><strong>Price - </strong> KSH {Number(lst.price).toLocaleString()}</p>
    <p class="mt-4 font-lufga"><strong>Deposit - </strong> KSH {Number(lst.deposit).toLocaleString()}</p>
    <p class="mt-4 font-lufga"><strong>Repayment - </strong> KSH {Number(lst.monthlyInstallments).toLocaleString()} per month</p>

  </div><div class="lg:col-span-3">
      <div class="border-b border-gray-300">
        <nav class="flex gap-4">
          <a href="#" title="" class="border-b-2 border-gray-900 py-4 text-sm font-medium text-gray-900 hover:border-gray-400 hover:text-gray-800"> Description </a>

          <a href="#" title="" class="inline-flex items-center border-b-2 border-transparent py-4 text-sm font-medium text-gray-600">
            Reviews
            <span class="ml-2 block rounded-full bg-gray-500 px-2 py-px text-xs font-bold text-gray-100"> 0 </span>
          </a>
        </nav>
      </div>

      <div class="mt-8 flow-root sm:mt-12">
        <h1 class="text-2xl font-lufgaBold">{lst.type}</h1>
        <p class="mt-4 font-lufga">{lst.detailedDescription}</p>
        {/*<h1 class="mt-8 text-3xl font-bold">From the Fine Farms of Brazil</h1>
    <p class="mt-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio numquam enim facere.</p>
    <p class="mt-4">Amet consectetur adipisicing elit. Optio numquam enim facere. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore rerum nostrum eius facere, ad neque.</p> */}
      </div>
    </div></>
  ))
)
  :
  (
    <div class="flex justify-center items-center h-full">
  <img class="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt="" />
</div>
  )}
</div>


  </div>
</section>

            <Footer />
        </main>
    );
}

export default AboutProject;