import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useParams, useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import {
    TETabs,
    TETabsContent,
    TETabsItem,
    TETabsPane,
  } from "tw-elements-react";
  import {
    Modal,
    Ripple,
    initTE,
  } from "tw-elements";
import ManageProjects from "./Admin/ManageProjects";
import Managers from "./Admin/Managers_old";
import Payments from "./Admin/Payments";
import jwtDecode from 'jwt-decode';
import { BASE_URL} from '../config';

const Account = () => {
    initTE({ Modal, Ripple });
    const [justifyActive, setJustifyActive] = useState("tab1");
    const [users, setUsers] = useState([]);
    const {id} = useParams();
    const [userProfile, setUserProfile] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const navigate = useNavigate();

    const token = Cookies.get('token');
    //axios.defaults.withCredentials = true;
    const GetProfileData = (userId) =>{
      fetch(`${BASE_URL}/api/getUserProfile/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          console.log(data.Profile[0]);
          setUserProfile(data.Profile[0]);
        } else {
          //alert("Error");
          console.log(data.Message);
        }
      })
      .catch((error) => console.log(error));
    }
    useEffect(() => {

      //const token = localStorage.getItem('token');
      if (token) {
        try {
            const tokenPayload = jwtDecode(token);
            const expirationTime = new Date(tokenPayload.exp * 1000);
            const currentTime = new Date();

            if (currentTime > expirationTime) {
                Cookies.remove('token');
                localStorage.clear();
                navigate('/login');
            }else{
              GetProfileData(tokenPayload.id);
                const headers = {
                  authorization: `Bearer ${token}`,
                };

                fetch(`${BASE_URL}/api/getUsers`, { headers })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.Status === "Success") {
                      console.log(data.Users);

                      setUsers(data.Users);
                      setFilteredUsers(data.Users);
                    } else {
                      console.log(data.Error);
                    }
                  })
                  .catch((error) => console.log(error));

                  //profile

            }
        } catch (error) {
            // Error decoding token or token is invalid
        }
    } else {
        // No token found, user is not authenticated
        navigate('/login');
    }


    }, []);

    const checkTokenAndNavigate = (tabKey) => {
      const token = Cookies.get('token');

      if (token) {
          try {
              const tokenPayload = jwtDecode(token);
              const expirationTime = new Date(tokenPayload.exp * 1000);
              const currentTime = new Date();

              if (currentTime > expirationTime) {
                  // Session has expired, clear the token from cookie
                  Cookies.remove('token');
                  localStorage.clear();
                  navigate('/login');
              } else {
                  handleJustifyClick(tabKey);
              }
          } catch (error) {
              // Error decoding token or token is invalid
          }
      } else {
          // No token found, user is not authenticated
          navigate('/login');
      }
  };


  // Search handler function
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    if (!searchTerm) {
      setFilteredUsers(users); // Reset to original users if search term is empty
    } else {
      const filtered = users.filter((user) =>
        user.FullName.toLowerCase().includes(searchTerm) ||
        user.DateOfBirth.toString().includes(searchTerm) ||
        user.NationalID.toString().includes(searchTerm) ||
        user.KRAPin.toLowerCase().includes(searchTerm) ||
        user.County.toLowerCase().toString().includes(searchTerm) ||
        user.PhoneNumber.includes(searchTerm)
      );
      setFilteredUsers(filtered);
    }
  };

    const handleJustifyClick = (value) => {
        if (value === justifyActive) {
          return;
        }
        setJustifyActive(value);
      };




    return (

        <main>
            <Header />
            <section class="container px-4 mx-auto my-20">



            {/* Tabs Section */}
        <div class="mx-auto my-8 max-w-screen-xl overflow-hidden rounded-[20px] border shadow-lg shadow-[#efd27b] bg-white">
        <div class="flex items-center gap-x-2 mt-4 ml-4">
                {userProfile ? (
        <div>
          <img class="object-cover w-10 h-10 rounded-lg" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" />
                <div>
                    <h2 class="font-medium text-gray-800 dark:text-white ">{userProfile.FullName}</h2>
                    <p class="text-sm font-normal text-gray-600 dark:text-gray-400">{userProfile.role ? userProfile.role.toUpperCase() : ''}</p>
                </div>
          {/* Add more fields as needed */}
        </div>
      ) : (
        <p>Loading profile...</p>
      )}
            </div>
          <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
            <p
              className="mb-5 text-center font-bold text-[#707070] sm:text-[18px] lg:text-xl dark:text-gray-400 font-lufga"
              data-aos="fade-up"
            >
              Administration
            </p>
          </div>
          <div className="mb-3 mx-16">
      <TETabs justify>
        <TETabsItem
          onClick={() => handleJustifyClick("tab1")}
          active={justifyActive === "tab1"}
          color="warning"
        >
          <h3 className="font-lufgaBold text-lg normal-case">Registered Clients.</h3>
        </TETabsItem>
        <TETabsItem
          onClick={() => handleJustifyClick("tab2")}
          active={justifyActive === "tab2"}
          color="warning"
        >
          <h3 className="font-lufgaBold text-lg normal-case">Account Managers.</h3>

        </TETabsItem>
        <TETabsItem
          onClick={() => handleJustifyClick("tab3")}
          active={justifyActive === "tab3"}
          color="warning"
        >
          <h3 className="font-lufgaBold text-lg normal-case">Manage Projects.</h3>

        </TETabsItem>
        <TETabsItem
          onClick={() => checkTokenAndNavigate("tab4")} // Pass the tab key to the function
          active={justifyActive === "tab4"}
          color="warning"
          // disabled
        >
          <h3 className="font-lufgaBold text-lg normal-case">Payments.</h3>

        </TETabsItem>
      </TETabs>

      <TETabsContent>
        <TETabsPane show={justifyActive === "tab1"}>
          {/* Tab 1 content */}

    <div class="flex flex-col mt-6">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

            <div className="relative mb-10 w-full flex items-center justify-between rounded-md">
              <input
                onChange={handleSearch}
                type="text"
                name="search"
                className="h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-40 pl-12 shadow-sm outline-none focus:border-[#F2B807] focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search"
              />
            </div>
                <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Full Names</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">DOB</th>

                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">ID or Passport No.</th>




                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">KRA PIN No.</th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">County</th>
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Phone No</th>
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Project Type</th>
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Action</th>

                                {/* <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!filteredUsers.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            filteredUsers.map((user, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.FullName}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.DateOfBirth}</td>

                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.NationalID}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.KRAPin}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.County}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.PhoneNumber}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">Standard 4 Bedroom Maisonette</td>

                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                <div class="flex items-center gap-x-4">
                                                    <button class="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>
                                                    </button>

                                                    <Link to={`/client-profile/${user.ID}`} class="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </td>
                              </tr>
                            ))
                          )}
                        </tbody>


                    </table>
                </div>
            </div>
        </div>
    </div>
          </TETabsPane>
        <TETabsPane show={justifyActive === "tab2"}>
          {/* Tab 2 content */}
          <Managers />

          </TETabsPane>
        <TETabsPane show={justifyActive === "tab3"}>
          {/* Tab 3 content */}

        <ManageProjects />


          </TETabsPane>
        <TETabsPane show={justifyActive === "tab4"}>
          {/* Tab 4 content */}
          <Payments />
          </TETabsPane>
      </TETabsContent>
    </div>


        </div>
</section>
            <Footer />
        </main>
    );
}

export default Account;