import React, { useState, useEffect, Fragment} from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../../assets/promitto_logo_transparent.png';
import { BiSearch } from 'react-icons/bi';
import { RiArrowDropDownLine } from 'react-icons/ri'
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../config';
import jwtDecode from 'jwt-decode';
import AOS from 'aos';
import MainContent from '../MainContent/MainContent';
import Statistics from '../Sections/Statistics';
import Explore from '../Sections/Explore';
import Services from '../Sections/Services';
import WhyChooseUs from '../Sections/WhyChooseUs';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';

const Header = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [auth, setAuth] = useState(false)
  const [currentUser, setCurrentUser] = useState(undefined);
  const [role, setRole] = useState('');
  let navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Add a function to toggle the mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    AOS.init({ duration: 1000});
    const user = localStorage.getItem("token");
    if(user){
      const tokenPayload = jwtDecode(user);
      setRole(tokenPayload.role);
      setCurrentUser(user);
    }
  }, [])

  const handleLogout = () => {
    axios.get(`${BASE_URL}/api/logout`)
    .then(res => {
      if(res.data.Status === "Success"){
         localStorage.removeItem("token");
         localStorage.removeItem("username");
        navigate('/login');
        window.location.reload();
      }


    }).catch(err => console.log(err))
  }

  const activeLink = "py-2 pl-3 pr-6 text-[#F2B807] font-lufga font-semibold border-b-[3px] border-[#F2B807] lg:p-0 dark:text-gray-400 dark:border-gray-700 lg:dark:hover:text-white lg:dark:hover:bg-transparent";
  const defaultLink = "transition ease-in-out delay-150 duration-300 block py-2 pl-3 pr-6 text-gray-500 font-lufga font-semibold border-b-[3px] border-white hover:text-[#F2B807] lg:p-0 dark:text-gray-400 dark:border-gray-700 lg:dark:hover:text-white lg:dark:hover:bg-transparent";


  const mobileNavLinkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? 'bold' : 'bold',
      textDecoration: 'none',
      color: isActive ? '#666666' : '#666666',
      hover: isActive ? '#F2B807' : '#666666',
      background: isActive ? '#F2B807' : 'none',
      backgroundColor: isActive ? '#F2B807' : 'none',
      border: isActive ? '10px' : 'none',
      active: {  // Add styles for the active state
        borderBottom: '3.5px solid #F2B807',
      },
    };
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);



  const login = () => {
    navigate('/login');
  }
  const register = () => {
    navigate('/registration');
  }
  const dashboard = () => {
    const token = localStorage.getItem('token');
      //const token = localStorage.getItem('token');
      if (token) {
         const tokenPayload = jwtDecode(token);
         const role = tokenPayload.role;
         if(role === "super"){
          navigate('/admin-dashboard');
         }else if(role === "admin" || role === "pmanager"){
          navigate('/clients');
         }else{
          navigate('/dashboard');
         }
    } else {
        // No token found, user is not authenticated
        navigate('/login');
    }
  }
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const externalScript = document.createElement('script');
    externalScript.src = 'https://unpkg.com/flowbite@1.4.1/dist/flowbite.js';
    externalScript.async = true;
    document.body.appendChild(externalScript);

    return () => {
      document.body.removeChild(externalScript);
    };
  }, []);

  return (
    <>
      <header>
        {/* Mobile menu */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                className="relative m-4 flex h-1/2 w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex-1 px-3 bg-white divide-y space-y-1">

                  <ul className="flex flex-col mt-2 font-medium mx-2 mb-2">
        <li>
          <NavLink
            style={mobileNavLinkStyles}
            to="/"
            className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-b font-lufga font-semibold border-gray-100 rounded-lg hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            style={mobileNavLinkStyles}
            to="/about-us"
            className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-b font-lufga font-semibold border-gray-100 rounded-lg hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink
            style={mobileNavLinkStyles}
            to="https://account1.promittoltd.com/"
            className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-b font-lufga font-semibold border-gray-100 rounded-lg hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
          >
            Our Housing Projects
          </NavLink>
        </li>
        <li>
          <NavLink
            style={mobileNavLinkStyles}
            to="/how-to-own"
            className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-b font-lufga font-semibold border-gray-100 rounded-lg hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
          >
            How To Own
          </NavLink>
        </li>
        <li>
          <NavLink
            style={mobileNavLinkStyles}
            to="/contact-us"
            className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-y font-lufga font-semibold border-gray-100 rounded-lg hover:bg-[#F2B807] hover:text-white dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
          >
            Contact Us
          </NavLink>
        </li>
        <div class="flex items-center mt-4">
        <NavLink
                    to="https://account1.promittoltd.com/login"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      className="mr-3 inline-block rounded px-6 pb-2 pt-2.5 text-xs font-lufga uppercase leading-normal text-black transition duration-150 ease-in-out bg-neutral-100 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 motion-reduce:transition-none">
                      Login
                    </NavLink>
                    <NavLink to="https://account1.promittoltd.com/register">
                    <button
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      class="mr-3 inline-block rounded bg-[#F2B807] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-gray shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#F2B807] hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#F2B807] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#F2B807] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Create Account
                      </button>

                    </NavLink>


              {/* {currentUser ? (
              <><button onClick={handleLogout} className="flex items-center rounded-md bg-[#F2B807] px-4 py-1.5 text-center font-semibold text-[12px] font-lufga text-black hover:[#F2B807] hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">
                    Logout
                  </button><button
                    onClick={dashboard}
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    class="ml-3 inline-block rounded bg-gray-700 px-4 pb-2 py-1.5 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#F2B807] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#F2B807] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                      Dashboard
                    </button></>
                    ) : (
                      <><button
                      onClick={login}
                        type="button"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        className="mr-3 inline-block rounded px-4 pb-1.5 pt-1.5 text-xs font-lufga uppercase leading-normal text-black transition duration-150 ease-in-out bg-neutral-100 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 motion-reduce:transition-none">
                        Login
                      </button>
                      <button
                      onClick={register}
                        type="button"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        class="mr-3 inline-block rounded bg-[#F2B807] px-4 pb-1.5 pt-1.5 text-xs font-light uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#F2B807] hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#F2B807] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#F2B807] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                          Create Account
                        </button></>
                    )} */}

              </div>
      </ul>

               </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Navbar */}
        <nav className="z-10 fixed top-0 flex-no-wrap flex w-full items-center justify-between bg-white border-gray-200 py-4 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start lg:py-4">
          <div className="flex w-full flex-wrap items-center justify-between px-3">
            {/* Hamburger button for mobile view */}
            <button
            onClick={() => setMobileFiltersOpen(true)}
              className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
              type="button"
              data-te-collapse-init
              data-te-target="#navbarSupportedContent4"
              aria-controls="navbarSupportedContent4"
              aria-expanded="false"
              aria-label="Toggle navigation">
              {/* <!-- Hamburger icon --> */}
              <span class="[&>svg]:w-7">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-7 w-7">
                  <path
                    fill-rule="evenodd"
                    d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>
            <a className="py-1.5 ml-2 mr-5 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 lg:mb-0 lg:mt-0 lg:hidden"
          href="/"><img src={logo} className='h-10 lg:h-12' alt="TE Logo" loading="lazy" /></a>

            {/* Collapsible navigation container */}
            <div className="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto" id="navbarSupportedContent1" data-te-collapse-item>
              {/* Logo */}
              <a className="mb-4 ml-2 mr-5 mt-3 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 lg:mb-0 lg:mt-0" href="/">
                <img src={logo} className='h-10 lg:h-12' alt="TE Logo" loading="lazy" />
              </a>

              {/* Left navigation links */}

              <div
      class="!visible mt-2 hidden flex-grow basis-[100%] items-center justify-center lg:mt-0 lg:!flex lg:basis-auto"
      id="navbarSupportedContent8"
      data-te-collapse-item>
      <ul
        class="list-style-none flex flex-col pl-0 lg:mt-1 lg:flex-row"
        data-te-navbar-nav-ref>

        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="/"
          className={({ isActive }) => (isActive ? activeLink : defaultLink)}
          >Home
          </NavLink>
        </li>
        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="/about-us"
          className={({ isActive }) => (isActive ? activeLink : defaultLink)}
         >About Us
          </NavLink>
        </li>
        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="https://account1.promittoltd.com/"
          className={({ isActive }) => (isActive ? activeLink : defaultLink)}
          >Our Housing Projects
          </NavLink>
        </li>
        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="/how-to-own"
          className={({ isActive }) => (isActive ? activeLink : defaultLink)}
          >How to Own
          </NavLink>
        </li>
        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="/contact-us"
          className={({ isActive }) => (isActive ? activeLink : defaultLink)}
          >Contact Us
          </NavLink>
        </li>
      </ul>
    </div>
              {/* Right navigation button */}
              <div class="flex items-center">
              <NavLink
                    to="https://account1.promittoltd.com/login"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      className="mr-3 inline-block rounded px-6 pb-2 pt-2.5 text-xs font-lufga uppercase leading-normal text-black transition duration-150 ease-in-out bg-neutral-100 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 motion-reduce:transition-none">
                      Login
                    </NavLink>
                    <NavLink to="https://account1.promittoltd.com/register">
                    <button
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      class="mr-3 inline-block rounded bg-[#F2B807] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-gray shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#d6ae36] hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#F2B807] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#F2B807] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Create Account
                      </button>
                    </NavLink>

              {/* {currentUser ? (
              <><button onClick={handleLogout} className="flex items-center rounded-md bg-[#F2B807] px-5 py-2.5 text-center font-semibold text-[12px] font-lufga text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">
                    Logout
                  </button><button
                    onClick={dashboard}
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    class="ml-3 inline-block rounded bg-gray-700 px-6 pb-2 pt-2.5 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#F2B807] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#F2B807] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                      Dashboard
                    </button></>
  ) : (
                    <>
                    <NavLink
                    to="https://account.promittoltd.com/login"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      className="mr-3 inline-block rounded px-6 pb-2 pt-2.5 text-xs font-lufga uppercase leading-normal text-black transition duration-150 ease-in-out bg-neutral-100 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 motion-reduce:transition-none">
                      Login
                    </NavLink>
                    <NavLink
                    to="https://account.promittoltd.com/register"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      class="mr-3 inline-block rounded bg-[#F2B807] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-gray shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#F2B807] hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#F2B807] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#F2B807] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                        Create Account
                      </NavLink></>
  )} */}

              </div>
            </div>
          </div>
        </nav>

      </header>

      </>
  );
};

export default Header;
