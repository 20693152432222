import React, { useState, useEffect } from "react";
import AdminHeader from "./Components/AdminHeader";
import Aside from "./Components/Aside";
import { BASE_URL} from '../../config';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import FileUpload from "./ClientProjectsComponents/file-upload.component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegSave } from "react-icons/fa";
import Loader from "./Loader";
import Swal from "sweetalert2";

const EditProduct = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [saving, setSaving] = useState(false);
    const [options, setOptions] = useState([]);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [selectedImage1ListingFile, setSelectedImage1ListingFile] = useState(null);
    const [selectedImage2ListingFile, setSelectedImage2ListingFile] = useState(null);
    const [selectedImage3ListingFile, setSelectedImage3ListingFile] = useState(null);
    const [selectedImage4ListingFile, setSelectedImage4ListingFile] = useState(null);

    const [image1ButtonMessage, setImage1ButtonMessage] = useState('Upload');
    const [image2ButtonMessage, setImage2ButtonMessage] = useState('Upload');
    const [image3ButtonMessage, setImage3ButtonMessage] = useState('Upload');
    const [image4ButtonMessage, setImage4ButtonMessage] = useState('Upload');
    const [data, setData] =useState({
        type: '',
        price: '',
        deposit: '',
        monthlyInstallments:'',
        size: '',
        salerent: '',
        bedrooms: '',
        description: '',
        detailedDescription: '',
        featured: ''
    })

    useEffect(() => {
      fetch(`${BASE_URL}/api/getListingById/${id}`)
        .then(response => response.json())
        .then((data) => {
          if (data.Status === "Success") {
            setData({...data, type: data.Listing[0].type, price: data.Listing[0].price, deposit: data.Listing[0].deposit, monthlyInstallments: data.Listing[0].monthlyInstallments, size: data.Listing[0].size,
                salerent: data.Listing[0].salerent, bedrooms: data.Listing[0].bedrooms, description: data.Listing[0].description, detailedDescription: data.Listing[0].detailedDescription, featured: data.Listing[0].featured})
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error));
    }, [id]);


    const submitEditedDetails = async (event) => {
      event.preventDefault();

      await axios.put(`${BASE_URL}/api/editProductListing/${id}`, data)
      .then(res =>{
        console.log(res.data);
        if(res.data.Status === "Success"){
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            onClose: () => {
              setTimeout(() => {
                navigate('/project-management');
              }, 1000); // Adjust the delay as needed
            },
          });

        }else{
          alert("Failed to update, try again or contact administrator");
        }

      })
      .catch(err => console.log(err));

    }
    const [newProductInfo, setnewProductInfo] = useState({
        listingImages: []
      });

      const updateUploadedFiles = (files) =>
        setnewProductInfo({ ...newProductInfo, listingImages: files });
    const handleSubmit = async (event) => {
        event.preventDefault();
         // Check if there are no files to upload
        if (newProductInfo.listingImages.length === 0) {
            // Show an alert to the user
            alert("Please upload at least one file before submitting.");
            return; // Prevent further execution
        }else if(newProductInfo.listingImages.length !== 4){
          alert("You are required to upload 4 photos.");
        }else{
          setSaving(true); // Start the saving process
          try {
            // Create a FormData object to send files to the API
            const formData = new FormData();
            newProductInfo.listingImages.forEach((file) => {
              formData.append("listingsFiles", file);
            });

            // Send the FormData to your Node.js API
            const response = await fetch(`${BASE_URL}/api/uploadListingsFiles/${id}`, {
              method: "PUT",
              body: formData,
              credentials: 'include'
            });

            if (response.ok) {
              // Handle success, e.g., show a success message
              setUploadSuccess(true);
              toast.success("Upload was successfull", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                });
                setSaving(false);

            } else {
              // Handle errors, e.g., show an error message
              setSaving(false);
              console.error("Failed to upload files.");
            }
          } catch (error) {
            setSaving(false);
            console.error("Error:", error);
          }
        }


      };


      const [listing, setListing] = useState(null);
      const [listingImages, setListingImages] = useState([]);
      const [selectedImage, setSelectedImage] = useState('');

      useEffect(() => {
        loadImageData();
      }, [id]);

      const loadImageData = () =>{
        fetch(`${BASE_URL}/api/getListingById/${id}`)
          .then(response => response.json())
          .then((data) => {
            if (data.Status === "Success") {
              console.log(data.Listing[0].more_images);
              setListing(data.Listing);
              if (data.Listing[0].more_images) {
                setListingImages(data.Listing[0].more_images);
                setSelectedImage(data.Listing[0].more_images[0]);
              }
            } else {
              return;
            }
          })
          .catch((error) => console.log(error));
      }

      const handleImage1FileChange = (event) => {
        setSelectedImage1ListingFile(event.target.files[0]);
      };
      const handleImage2FileChange = (event) => {
        setSelectedImage2ListingFile(event.target.files[0]);
      };
      const handleImage3FileChange = (event) => {
        setSelectedImage3ListingFile(event.target.files[0]);
      };
      const handleImage4FileChange = (event) => {
        setSelectedImage4ListingFile(event.target.files[0]);
      };

      const uploadListingImage = async (e, imageName) => {
        e.preventDefault();
        const formData = new FormData();

        switch (imageName){
          case 'image1':
            formData.append("listingFile", selectedImage1ListingFile);
            break;
          case 'image2':
            formData.append("listingFile", selectedImage2ListingFile);
            break;
          case 'image3':
            formData.append("listingFile", selectedImage3ListingFile);
            break;
          case 'image4':
            formData.append("listingFile", selectedImage4ListingFile);
            break;
          default:
            formData.append("document", null);
        }
        try {
          switch (imageName){
            case 'image1':
              setImage1ButtonMessage('Uploading....');
              break;
            case 'image2':
              setImage2ButtonMessage('Uploading....');
              break;
            case 'image3':
              setImage3ButtonMessage('Uploading....');
              break;
            case 'image4':
              setImage4ButtonMessage('Uploading....');
              break;
            default:
              setImage1ButtonMessage('Upload');
              setImage2ButtonMessage('Upload');
              setImage3ButtonMessage('Upload');
              setImage4ButtonMessage('Upload');
          }

            const response = await fetch(`${BASE_URL}/api/uploadListingsFiles/${id}`, {
                method: 'PUT',
                body: formData,
                credentials: 'include'
            });
            const data = await response.json();
            console.log(data);
            if (data.Status === "Success") {
            loadImageData();
              switch (imageName){
                case 'image1':
                  setImage1ButtonMessage('Uploaded');
                  setSelectedImage1ListingFile('');
                  break;
                case 'image2':
                  setImage2ButtonMessage('Uploaded');
                  setSelectedImage2ListingFile('');
                  break;
                case 'image3':
                  setImage3ButtonMessage('Uploaded');
                  setSelectedImage3ListingFile('');
                  break;
                case 'image4':
                  setImage4ButtonMessage('Uploaded');
                  setSelectedImage4ListingFile('');
                  break;
                default:
                  setImage1ButtonMessage('Upload');
                  setImage2ButtonMessage('Upload');
                  setImage3ButtonMessage('Upload');
                  setImage4ButtonMessage('Upload');
              }
                console.log(data);
                Swal.fire({
                    icon: 'success',
                    title: data.Message,
                    showConfirmButton: false,
                    timer: 3000,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: data.Message,
                    showConfirmButton: false,
                    timer: 3000,
                });
                console.log(data);
                setImage1ButtonMessage('Upload');
              setImage2ButtonMessage('Upload');
              setImage3ButtonMessage('Upload');
              setImage4ButtonMessage('Upload');
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };
    return (
        <section>
        <div>
          <AdminHeader />
           <div className="flex overflow-hidden bg-white pt-16">
              <Aside />
              <div className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
              <div id="main-content" className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
                 <main>
                    <div className="pt-6 px-4">

                    <div class="flex flex-col mt-6">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
                     <div className="mb-4 flex items-center justify-between">
                        <div>
                           <h3 className="text-xl font-bold text-gray-900 mb-2">Edit Product Details</h3>
                        </div>
                        <div className="flex-shrink-0">
                           <a
                           data-te-toggle="modal"
                           data-te-target="#listingfiles"
                           data-te-ripple-init
                           data-te-ripple-color="light"
                            className="text-sm cursor-pointer bg-gray-100 font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">Add Images</a>
                        </div>
                     </div>
                     <div className="flex flex-col mt-8">
                        <div className="overflow-x-auto rounded-lg">
                           <div className="align-middle inline-block min-w-full">
                           <form className="mt-10" onSubmit={submitEditedDetails}>
                  <div class="grid gap-6 mb-6 md:grid-cols-3">
                    <div>
                    <label class="text-gray-700 dark:text-gray-200 font-lufga" for="featured">
                        Featured <span className="text-[#F2B807]">*</span>
                    </label>
                    <select
                        name="featured"
                        value={data.featured}
                        onChange={e => setData({...data, featured: e.target.value})}
                        id="featured"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required
                    >
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                    </select>
                    </div>

                        <div>
                                <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                                Project Type. <span className="text-[#F2B807]">*</span>
                                </label>
                                <input
                                name="type"
                            value={data.type}
                            onChange={e => setData({...data, type: e.target.value})}
                                id="type" type="text"
                                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                                required/>
                        </div>
                        <div>
                            <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                            Price. <span className="text-[#F2B807]">*</span>
                            </label>
                            <input
                            name="price"
                            value={data.price}
                            onChange={e => setData({...data, price: e.target.value})}
                            id="price" type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                            required/>
                        </div>
                        <div>
                            <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                            Deposit. <span className="text-[#F2B807]">*</span>
                            </label>
                            <input
                            name="deposit"
                            value={data.deposit}
                            onChange={e => setData({...data, deposit: e.target.value})}
                            id="deposit" type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                            required/>
                        </div>
                        <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Monthly Installments. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="monthlyInstallments"
                        value={data.monthlyInstallments}
                        onChange={e => setData({...data, monthlyInstallments: e.target.value})}
                        id="monthlyInstallments" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                        </div>
                        <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Size in Square Meters. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="size"
                        value={data.size}
                        onChange={e => setData({...data, size: e.target.value})}
                        id="size" type="number" min={1}
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                            <label class="text-gray-700 dark:text-gray-200 font-lufga" for="salerent">
                                Sale or Rent <span className="text-[#F2B807]">*</span>
                            </label>
                            <select
                                name="salerent"
                                value={data.salerent}
                                onChange={e => setData({...data, salerent: e.target.value})}
                                id="salerent"
                                class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                                required
                            >
                                <option value="Sale">Sale</option>
                                <option value="Rent">Rent</option>
                            </select>
                        </div>

                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        No. of Bedrooms <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="bedrooms"
                        value={data.bedrooms}
                        onChange={e => setData({...data, bedrooms: e.target.value})}
                        id="bedrooms" type="number" min={1}
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Description <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="description"
                        value={data.description}
                        onChange={e => setData({...data, description: e.target.value})}
                        id="description" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <div>
                            <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                                Detailed Description <span class="text-[#F2B807]">*</span>
                            </label>
                            <textarea
                                name="detailedDescription"
                                value={data.detailedDescription}
                                onChange={e => setData({...data, detailedDescription: e.target.value})}
                                id="detailedDescription"
                                type="text"
                                class="block w-[200%] h-40 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                                required
                            />
                        </div>
                    </div>

                  </div>
                  <div class="grid grid-cols-2 md:grid-cols-4 gap-4">

                  </div>
                  <div className="flex-shrink-0">
                  <button type="submit"
                    className="text-sm bg-[#F2B807] font-medium text-black rounded-lg p-2 flex items-center"
                  ><FaRegSave className="mr-2 text-lg text-black" />Save Changes
                  </button>
                  </div>

                    </form>




                    <div
            data-te-modal-init
            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="listingfiles"
            tabindex="-1"
            aria-labelledby="exampleModalXlLabel"
            aria-modal="true"
            role="dialog">
                <div
                    data-te-modal-dialog-ref
                    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
                    <div
                        class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                        <div
                            class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

                            <h5
                                class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                id="exampleModalXlLabel">
                                Upload 4 Photos of type PNG or JPEG
                            </h5>
                            <button
                                type="button"
                                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                data-te-modal-dismiss
                                aria-label="Close">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="h-6 w-6">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        {/* <!--Modal body--> */}
                        <div class="relative p-4">
                        <div className='mx-8'>
                        <form onSubmit={(e) => uploadListingImage(e, "image1")} class="flex flex-wrap items-center justify-center space-y-2 md:space-y-0 md:space-x-2">
                          <div class="flex flex-col w-full md:w-auto md:flex-grow md:flex-shrink-0">
                            <p class="mb-2 text-sm font-medium">Main image</p>
                            <input type="file" name="passportfile" accept=".png, .jpg, .jpeg" class="block w-48 md:w-full" onChange={handleImage1FileChange} required />
                          </div>

                          {selectedImage1ListingFile &&
                            <div class="w-full md:w-64 h-64">
                              <img src={URL.createObjectURL(selectedImage1ListingFile)} alt="Image" class="w-full h-full object-contain" />
                            </div>
                          }

                          <div class="flex flex-col items-center px-24 md-8">
                            <button type="submit" class="bg-primary px-8 py-2 text-xs h-[50px] text-white font-medium uppercase rounded-md shadow hover:bg-primary-600">
                              {image1ButtonMessage}
                            </button>
                          </div>
                        </form>
                        <form onSubmit={(e) => uploadListingImage(e, "image2")} class="flex flex-wrap items-center justify-center space-y-2 md:space-y-0 md:space-x-2">
                          <div class="flex flex-col w-full md:w-auto md:flex-grow md:flex-shrink-0">
                            <p class="mb-2 text-sm font-medium">Second Image</p>
                            <input type="file" name="passportfile" accept=".png, .jpg, .jpeg" class="block w-48 md:w-full" onChange={handleImage2FileChange} required />
                          </div>

                          {selectedImage2ListingFile &&
                            <div class="w-full md:w-64 h-64">
                              <img src={URL.createObjectURL(selectedImage2ListingFile)} alt="Image" class="w-full h-full object-contain" />
                            </div>
                          }

                          <div class="flex flex-col items-center px-24 md-8">
                            <button type="submit" class="bg-primary px-8 py-2 text-xs h-[50px] text-white font-medium uppercase rounded-md shadow hover:bg-primary-600">
                              {image2ButtonMessage}
                            </button>
                          </div>
                        </form>
                        <form onSubmit={(e) => uploadListingImage(e, "image3")} class="flex flex-wrap items-center justify-center space-y-2 md:space-y-0 md:space-x-2">
                          <div class="flex flex-col w-full md:w-auto md:flex-grow md:flex-shrink-0">
                            <p class="mb-2 text-sm font-medium">Third Image</p>
                            <input type="file" name="passportfile" accept=".png, .jpg, .jpeg" class="block w-48 md:w-full" onChange={handleImage3FileChange} required />
                          </div>

                          {selectedImage3ListingFile &&
                            <div class="w-full md:w-64 h-64">
                              <img src={URL.createObjectURL(selectedImage3ListingFile)} alt="Image" class="w-full h-full object-contain" />
                            </div>
                          }

                          <div class="flex flex-col items-center px-24 md-8">
                            <button type="submit" class="bg-primary px-8 py-2 text-xs h-[50px] text-white font-medium uppercase rounded-md shadow hover:bg-primary-600">
                              {image3ButtonMessage}
                            </button>
                          </div>
                        </form>
                        <form onSubmit={(e) => uploadListingImage(e, "image4")} class="flex flex-wrap items-center justify-center space-y-2 md:space-y-0 md:space-x-2">
                          <div class="flex flex-col w-full md:w-auto md:flex-grow md:flex-shrink-0">
                            <p class="mb-2 text-sm font-medium">Fourth image</p>
                            <input type="file" name="passportfile" accept=".png, .jpg, .jpeg" class="block w-48 md:w-full" onChange={handleImage4FileChange} required />
                          </div>

                          {selectedImage4ListingFile &&
                            <div class="w-full md:w-64 h-64">
                              <img src={URL.createObjectURL(selectedImage4ListingFile)} alt="Image" class="w-full h-full object-contain" />
                            </div>
                          }

                          <div class="flex flex-col items-center px-24 md-8">
                            <button type="submit" class="bg-primary px-8 py-2 text-xs h-[50px] text-white font-medium uppercase rounded-md shadow hover:bg-primary-600">
                              {image4ButtonMessage}
                            </button>
                          </div>
                        </form>
        </div>

                        </div>
                    </div>
                </div>
            </div>


                           </div>
                        </div>
                     </div>
                  </div>

                    </div>
                </div>
            </div>
                    </div>
                 </main>
                 <p className="text-center text-sm text-gray-500 my-10">
                    &copy; 2023 <a href="https://promittoltd.com" className="hover:underline" target="_blank">Promitto</a>. All rights reserved.
                 </p>
              </div>
           </div>
           <script async defer src="https://buttons.github.io/buttons.js"></script>
           <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
        </div>
        </section>
    );
}

export default EditProduct;