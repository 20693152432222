import React, { useEffect } from "react";

import { GiTakeMyMoney } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { GiCornerFlag } from "react-icons/gi";
import { BsArrowRightCircle } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';
import { Link } from "react-router-dom";

import mission from '../../assets/about-us//josue-isai-ramos-figueroa-qvBYnMuNJ9A-unsplash.png';
import vision from '../../assets/about-us/WEB_Photo-2.png';

import AOS from "aos";
const About = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const visionImage = {
    backgroundImage: `url(${vision})`,
};
const missionImage = {
    backgroundImage: `url(${mission})`,
};
  return (
    <section class="bg-white dark:bg-gray-900 lg:mx-14 sm:mx-8 mt-8">
      <div class="max-w-screen-xl px-2 py-8 mx-auto lg:py-8 lg:px-2">
        <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-2 lg:space-y-0">
          {/* <!-- Pricing Card 1--> */}
          <div class="flex flex-col max-w-[650px] max-h-full p-6 mx-auto text-left text-gray-900 bg-[#F9F9F9D9] border border-gray-100 rounded-tl-none rounded-br-none rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
             <div className="lg:pt-16 sm:pt-8">
             <h3 class="mb-4 lg:text-[100px] text-[#F2B807] font-semibold font-lufgaBold">About Us</h3>
            <p class=" text-gray-600 sm:text-[16px] dark:text-gray-600 font-semibold font-lufga mr-8">
            Promitto is a corporation with a distinctive aim to boost and support individuals to achieve their dreams of home ownership through provision of Construction Financing at competitive interest rates and provision of full construction services.
            </p>
            <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 pt-8">

           <Link to='/register' class="inline-flex items-center font-lufga bg-[#F2B807] justify-center w-full px-5 py-3 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  focus:ring-4 focus:ring-gray-100 dark:text-white" data-aos="fade-up">
                       Enroll Now <BsArrowRightCircle className='text-black font-bold ml-2' />
                    </Link>
            </div>
             </div>

          </div>

          <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-5 lg:space-y-0 lg:m-6 sm:mx-2">
            {/* <!-- Pricing Card 2--> */}
            <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-6 dark:bg-gray-800 dark:text-white">
              <div className="flex items-left justify-left mb-4">
                <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                  <FaRegEye className="text-[#010440] text-2xl" />
                </div>
              </div>
              <h3 class="mb-4 text-lg font-semibold font-lufgaBold">Our Vision.</h3>
              <p class="text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
              To be your trusted partner to home ownership.
              </p>
            </div>
            {/* <!-- Pricing Card 3--> */}
            <div className="flex flex-col max-w-lg mx-auto">
            <img
                alt="gallery"
                class="h-full w-full rounded-[20px] object-cover object-center"
                src={vision} />
            </div>



            {/* <!-- Pricing Card 4--> */}
            <div className="flex flex-col max-w-lg mx-auto">
            <img
                alt="gallery"
                class="h-full w-full rounded-[20px] object-cover object-center"
                src={mission} />
            </div>
            {/* <!-- Pricing Card 5--> */}
            <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-[#F2B807] border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-6 dark:bg-gray-800 dark:text-white">
              <div className="flex items-left justify-left mb-4">
                <div className="w-16 h-16 bg-[#010440] rounded-full flex items-center justify-center">
                  <GiCornerFlag className="text-[#F2B807] text-2xl" />
                </div>
              </div>
              <h3 class="mb-4 text-lg font-semibold font-lufgaBold">Our Mission</h3>
              <p class="text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
              Empower individuals to become home owners.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
