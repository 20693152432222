import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import testimonial_image from '../../assets/testimonial_image.jpg';
import Slider from 'react-slick';
import {BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill} from 'react-icons/bs';
import {FaUserCircle} from 'react-icons/fa';
import { testimonialData } from './data';
import { Link } from 'react-router-dom';
export default function Testimonials () {
    const [defaultImage, setDefaultImage] = useState({});
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <BsFillArrowLeftCircleFill {...props} />
      );

      const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <BsFillArrowRightCircleFill {...props} />
      );
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
        //prevArrow: <SlickArrowLeft />,
        // nextArrow: <SlickArrowRight />,

    useEffect(() => {
        AOS.init({ duration: 2000});
    },[]);
    return (
        <section class="bg-white dark:bg-gray-900">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
            <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <h2 className="mb-4 text-4xl font-bold tracking-wide font-lufga text-[#F2B807] dark:text-white" data-aos="fade-down">Testimonials</h2>
                    <p className="mb-5 font-lufga text-gray-600 sm:text-[16px] dark:text-gray-400" data-aos="fade-down">
                    From Dream to Reality, Hear What Our Satisfied Clients Have to Say.
                     </p>

            </div>

            <Slider {...settings}>
        {testimonialData.map((item) => (
          <><div className="flex flex-col max-w-lg text-left text-gray-900 bg-white xl:p-8 dark:text-white">

            <div className='xl:-mx-1 md:mx-4 flex flex-col max-w-lg text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:-px-8 dark:bg-gray-800 dark:text-white"'>
              <div className='flex items-center gap-[10px] mx-4'>
                <div className="mt-6 w-12 h-12 border-gray-100 shadow dark:border-gray-600 rounded-3xl flex items-center justify-center overflow-hidden">
                  {/* Replace the 'path/to/image.png' with the actual path to your image file */}
                  <FaUserCircle className="rounded-full object-cover w-full h-full" />
                </div>
                <div className='leading-[25px]'>
                  <h2 className='text-lg text-smallTextColor font-[600] font-lufga'>{item.name}</h2>
                  <p className='text-gray-600 text-[14px] font-[500] font-lufga'>Client</p>
                </div>
              </div>
              <br />
              <h3 className="mx-4 text-[16px] font-semibold font-lufga">Recommended Highly...</h3>
              <p

              className="m-4 text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
                {item.testimonial}... <Link to='/testimonials'
                  className='font-lufgaBold text-[#F2B807] cursor-pointer'
                >Read more</Link>
              </p>

            </div>
          </div>
          </>
        ))}
      </Slider>

        </div>
      </section>
    );
};

//export default Testimonials;