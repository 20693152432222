import React, { useEffect } from "react";

import { GiTakeMyMoney } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { GiCornerFlag } from "react-icons/gi";
import { BsArrowRightCircle } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';

import mission from '../../assets/about-us//josue-isai-ramos-figueroa-qvBYnMuNJ9A-unsplash.png';
import vision from '../../assets/about-us/WEB_Photo-2.png';

import AOS from "aos";
const OwnHome = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const visionImage = {
    backgroundImage: `url(${vision})`,
};
const missionImage = {
    backgroundImage: `url(${mission})`,
};
  return (
    <section class="bg-white dark:bg-gray-900 lg:mx-14 sm:mx-8 mt-8">
      <div class="max-w-screen-xl px-2 py-8 mx-auto lg:py-24 lg:px-2">
        <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-2 lg:space-y-0">
          {/* <!-- Pricing Card 1--> */}
          <div class="flex flex-col max-w-[650px] max-h-full p-6 mx-auto text-left text-gray-900 bg-[#F9F9F9D9] border border-gray-100 rounded-tl-none rounded-br-none rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
             <div className="lg:pt-16 sm:pt-8">
             <h3 class="mb-4 lg:text-[80px] text-[#F2B807] font-semibold font-lufgaBold">Own a Home</h3>
            <p class=" text-gray-600 sm:text-[16px] dark:text-gray-600 font-semibold font-lufga mr-8">
           At Promitto Ltd, we have streamlined the path to homeownership,
making it easier and more accessible than ever before. With our
innovative approach, owning a home becomes an achievable reality.
We offer flexible financing options, tailored to suit your unique
circumstances, ensuring that your dream of homeownership fits
comfortably within your budget.
            </p>
            <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 pt-8">

           <a href="/registration" class="inline-flex items-center font-lufga bg-[#F2B807] justify-center w-full px-5 py-3 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  focus:ring-4 focus:ring-gray-100 dark:text-white" data-aos="fade-up">
                       Enroll Now <BsArrowRightCircle className='text-black font-bold ml-2' />
                    </a>
            </div>
             </div>

          </div>

          <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-5 lg:space-y-0 lg:m-6 sm:mx-2 items-center">
            {/* <!-- Pricing Card 2--> */}
            <div class="flex flex-col col-span-2 max-w-lg p-6 sm:-ml-4 text-left text-gray-900 bg-white">

              <h3 class="mt-4 text-2xl font-semibold font-lufgaBold">Jenga Nyumba Loan.</h3>
              <p class="text-gray-600 mt-3 sm:text-[16px] dark:text-gray-400 font-lufga">
              Why You Should Get It!.
              </p>
            </div>



            {/* <!-- Pricing Card 4--> */}
            <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-6 dark:bg-gray-800 dark:text-white">
              <div className="flex items-left justify-left mb-4">
                <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                  <FaRegEye className="text-[#010440] text-2xl" />
                </div>
              </div>
              <h3 class="mb-4 text-lg font-semibold font-lufgaBold">Sustainable Loans.</h3>
              <p class="text-gray-600 sm:text-[12px] dark:text-gray-400 font-lufga">
              Housing is a basic need. Promitto provides the affordable and sustainable Jenga Nyumba loan products geared towards providing affordable housing to its members.
              </p>
            </div>
            {/* <!-- Pricing Card 5--> */}
            <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-[#F2B807] border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-6 dark:bg-gray-800 dark:text-white">
              <div className="flex items-left justify-left mb-4">
                <div className="w-16 h-16 bg-[#010440] rounded-full flex items-center justify-center">
                  <GiCornerFlag className="text-[#F2B807] text-2xl" />
                </div>
              </div>
              <h3 class="mb-4 text-lg font-semibold font-lufgaBold">Construction Services.</h3>
              <p class="text-gray-600 sm:text-[12px] dark:text-gray-400 font-lufga">
              Attached to the Jenga Nyumba Loan Product, members are offered full construction services by the society’s contractors to help members construct with efficiency and timeliness.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OwnHome;
