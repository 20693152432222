import React, { useEffect } from "react";
import AOS from "aos";
import { GiTakeMyMoney } from "react-icons/gi";
import { BASE_URL } from "../../config";

const Savings = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <section class="py-2">
      <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
        <h2
          className="mb-2 text-4xl font-bold tracking-wide text-[#F2B807] dark:text-white font-lufga"
          data-aos="fade-down"
        >
          Savings
        </h2>
        <h2
          className="text-2xl tracking-tight text-[#212121] dark:text-white font-lufgaBold"
          data-aos="fade-left"
        >
          How to Subscribe
        </h2>
        <p
          className="mb-5 text-gray-600 font-bold sm:text-[16px] dark:text-gray-400 font-lufga"
          data-aos="fade-up"
        >
          Why you should Save with us.
        </p>
      </div>
      <div class="mx-auto grid max-w-screen-lg grid-cols-1 gap-5 p-5 sm:grid-cols-2 md:grid-cols-2 lg:gap-10">
        <article
          class="h-90 col-span-1 max-w-sm m-auto min-h-full cursor-pointer overflow-hidden rounded-[20px] pb-2 shadow-lg transition-transform duration-200 hover:translate-y-2"
          data-aos="fade-right"
        >
          <div className="mx-6 my-12">
            <div className="flex items-left justify-left my-8">
              <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                <GiTakeMyMoney className="text-[#010440] text-3xl" />
              </div>
            </div>
            <h3 class="mb-4 text-lg font-semibold font-lufgaBold">
              Deposit Account.
            </h3>
            <p class="text-gray-600 sm:text-[14px] dark:text-gray-400 font-lufga">
              Our clients are allowed to save 30% of their Bills of Quantities
              in installments via the members' deposit account.
            </p>
          </div>
        </article>

        <article
          class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2"
          data-aos="fade-left"
        >
          <h3 class="mb-4 text-2xl text-[#F2B807] font-semibold font-lufgaBold">
            Requirements for Opening an Account.
          </h3>
          <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
              <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                    Registration fees
                  </li>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                    Property search fees
                  </li>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                    Legal fees
                  </li>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                    Property charge
                  </li>
                </div>
              </ul>
            </li>
          </ol>
        </article>
      </div>

      <div class="max-w-screen-md mx-auto mb-4 text-center lg:mb-4 lg:mt-8">
        <h2
          className="text-3xl tracking-tight text-[#212121] dark:text-white font-lufgaBold"
          data-aos="fade-down"
        >
          Document Downloads (Bronchures)
        </h2>
      </div>
      <div class="mx-auto grid max-w-screen-lg grid-cols-1 gap-5 p-5 sm:grid-cols-2 md:grid-cols-2 lg:gap-10 mb-12">
        <article
          class="h-90 col-span-1 max-w-sm m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2"
          data-aos="fade-right"
        >
          <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
              <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">


                    <a href={`${BASE_URL}/bronchures/2 bedroom bungalow flatroof.pdf`}
                      rel="noopener noreferrer" className="flex items-center">
                        <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">2 Bedroom bungalow flatroof</li>
                    </a>

                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                <a href={`${BASE_URL}/bronchures/3 bedroom bungalow flatroof.pdf`}
                      rel="noopener noreferrer" className="flex items-center">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                    3 Bedroom bungalow flatroof
                  </li>
                  </a>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                <a href={`${BASE_URL}/bronchures/3 bedroom bungalow hidden roof.pdf`}
                      rel="noopener noreferrer" className="flex items-center">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  3 bedroom bungalow hidden roof
                  </li>
                  </a>
                </div>

              </ul>
            </li>
          </ol>
        </article>

        <article
          class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2"
          data-aos="fade-left"
        >
          <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
              <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                <a href={`${BASE_URL}/bronchures/3 bedroom bungalow Pitchroofed.pdf`}
                      rel="noopener noreferrer" className="flex items-center">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  3 bedroom bungalow Pitchroofed
                  </li>
                  </a>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                <a href={`${BASE_URL}/bronchures/3 bedroom mansion pitchroof.pdf`}
                      rel="noopener noreferrer" className="flex items-center">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  3 bedroom mansion pitchroof
                  </li>
                  </a>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                <a href={`${BASE_URL}/bronchures/3 bedroom mansion flatroof.pdf`}
                      rel="noopener noreferrer" className="flex items-center">
                  <li className="text-[22px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  3 bedroom mansion flatroof
                  </li>
                  </a>
                </div>
              </ul>
            </li>
          </ol>
        </article>
      </div>
    </section>
  );
};

export default Savings;
