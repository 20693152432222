import React from "react";
import {BsArrowRightCircle} from 'react-icons/bs';

import cta from "../../assets/choose-dream-house/cta.png";
import { icons } from "react-icons";

const CTA = () => {
    return(
        <section class="lg:mx-14">
        <div class="mx-auto max-w-screen-xl overflow-hidden rounded-[20px] rounded-b-none border shadow-lg md:pl-8 bg-[#F2B807]">
  <div class="flex flex-col overflow-hidden bg-[#F2B807] sm:flex-row md:h-80 lg:h-auto">
    <div class="flex w-full flex-col sm:w-1/2 sm:p-8 lg:w-3/5 my-20">
      <h2 class="text-xl font-lufgaBold text-gray-900 md:text-2xl lg:text-6xl">Become a home owner today by Enrolling as a member.</h2>
      <p class="max-w-2xl mb-6 mt-4 text-[#010440] font-lufga lg:mb-8 md:text-lg lg:text-lg dark:text-gray-400">
            Whether you're searching for a luxurious villa, a cozy family home, or a trendy urban apartment, we have the perfect option for you
            </p>
           <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">

           <a href="#" class="inline-flex items-center font-lufga bg-[#F2B807] justify-center w-full px-5 py-3 text-sm font-bold text-center text-black border-2 border-black rounded-lg sm:w-auto  focus:ring-4 focus:ring-gray-100 dark:text-white" data-aos="fade-up">
                       Enroll Now <BsArrowRightCircle className='text-black font-bold ml-2' />
                    </a>
            </div>
    </div>

    <div class="order-first ml-auto h-48 w-full bg-gray-700 sm:order-none sm:h-auto sm:w-1/2 lg:w-2/5">
      <img class="h-full w-full object-cover" src={cta} loading="lazy" />
    </div>
  </div>
</div>
</section>
    );
}

export default CTA;