import React, { useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { FaTwitterSquare, FaPinterest, FaFacebook, FaInstagram, FaYoutube, FaEnvelope, FaTiktok, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { BASE_URL} from '../config';
import { toast } from 'react-toastify';

const ContactUs = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const handleNameChange = (e) =>{
        setFullName(e.target.value);
      }
      const handleEmailChange = (e) =>{
        setEmail(e.target.value);
      }
      const handlePhoneChange = (e) =>{
        setPhoneNumber(e.target.value);
      }
      const handleMessageChange = (e) =>{
        setMessage(e.target.value);
      }
      const handleSubjectChange = (e) =>{
        setSubject(e.target.value);
      }

      const SendEmail = async (e) => {
        e.preventDefault();
        const data = { fullName, email, phoneNumber, subject, message };
        await fetch(`${BASE_URL}/api/send-contact-email`, {
        method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.Status === "Success"){
                setFullName('');
                setEmail('');
                setPhoneNumber('');
                setSubject('');
                setMessage('');
                toast.success(data.Message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });

            }else{
                alert("Failed to send email. Try again");
            }
        })
        }
    return (

        <main>
            <Header />
            <section className="bg-white dark:bg-gray-900 lg:mx-14 sm:mx-8 my-28">
            <div className="bg-white dark:bg-gray-900">
            <section className="bg-white dark:bg-gray-900 border-[3px] border-[#f5d77b] rounded-[20px]">
    <div className="container px-6 py-12 mx-auto">
        <div className="lg:flex lg:items-center lg:-mx-6">
            <div className="lg:w-1/2 lg:mx-6">
                <h1 className="text-xl font-lufgaBold text-gray-800 capitalize dark:text-white lg:text-2xl">
                    Contact us for <br /> more info
                </h1>

                <div className="mt-6 space-y-8 md:mt-8">
                    <p className="flex items-start -mx-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-[#f5d77b] dark:text-[#F2B807]" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>

                        <span className="mx-2 text-gray-700 font-lufga truncate w-72 dark:text-gray-400">
                        Pension Towers, Loita St, Nairobi
                        </span>
                    </p>

                    <p className="flex items-start -mx-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-[#f5d77b] dark:text-[#F2B807]" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>

                        <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400 font-lufga"> 0729 506506</span>
                    </p>

                    <p className="flex items-start -mx-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-[#f5d77b] dark:text-[#F2B807]" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>

                        <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400 font-lufga">info@promittoltd.com</span>
                    </p>
                </div>

                <div className="mt-6 w-80 md:mt-8">
                    <h3 className="text-gray-600 font-lufga dark:text-gray-300 ">Follow us</h3>

                    <div className="flex mt-4 -mx-1.5 ">
                        <a href="https://twitter.com/PromittoLtd" className="mx-1.5 dark:hover:text-[#F2B807] text-gray-400 transition-colors duration-300 transform hover:text-[#F2B807]">
                           <FaTwitter className="text-xl" />
                            </a>

                        <a href="https://www.tiktok.com/@jimmykagoni7" className="mx-1.5 dark:hover:text-[#F2B807] text-gray-400 transition-colors duration-300 transform hover:text-[#F2B807]">
                            <FaTiktok className="text-xl"/>
                        </a>

                        <a href="https://web.facebook.com/PromittoSacco/?_rdc=1&_rdr" className="mx-1.5 dark:hover:text-[#F2B807] text-gray-400 transition-colors duration-300 transform hover:text-[#F2B807]">
                            <FaFacebook className="text-xl" />
                        </a>

                        <a href="https://www.instagram.com/promitto_ltd/" className="mx-1.5 dark:hover:text-[#F2B807] text-gray-400 transition-colors duration-300 transform hover:text-[#F2B807]">
                            <FaInstagram className="text-xl" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="mt-8 lg:w-1/2 lg:mx-6">
                <div
                    className="w-full px-8 py-10 mx-auto overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-gray-900 lg:max-w-xl shadow-gray-300/50 dark:shadow-black/50">
                    <h1 className="text-lg font-lufgaBold text-gray-700">What do you want to ask</h1>

                    <form className="mt-6" onSubmit={SendEmail}>
                        <div className="flex-1">
                            <label className="block mb-2 font-lufga text-sm text-gray-600 dark:text-gray-200">Full Name</label>
                            <input
                            value={fullName}
                            onChange={handleNameChange}
                            required
                            type="text" placeholder="your full name" className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                        <div className="flex-1 mt-6">
                            <label className="block font-lufga mb-2 text-sm text-gray-600 dark:text-gray-200">Email address</label>
                            <input
                            value={email}
                            onChange={handleEmailChange}
                            required
                             type="email" placeholder="name@example.com" className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>
                        <div className="flex-1 mt-6">
                            <label className="block font-lufga mb-2 text-sm text-gray-600 dark:text-gray-200">Phone Number</label>
                            <input
                            value={phoneNumber}
                            onChange={handlePhoneChange}
                            required
                             type="text" placeholder="07xxxxxxx" className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>
                        <div className="flex-1 mt-6">
                            <label className="block font-lufga mb-2 text-sm text-gray-600 dark:text-gray-200">Subject</label>
                            <input
                            value={subject}
                            onChange={handleSubjectChange}
                            required
                             type="text" placeholder="subject of your message" className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                        <div className="w-full mt-6">
                            <label className="block font-lufga mb-2 text-sm text-gray-600 dark:text-gray-200">Message</label>
                            <textarea
                            value={message}
                            onChange={handleMessageChange}
                            required
                             className="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" placeholder="your message"></textarea>
                        </div>

                        <button type="submit" className="w-full px-6 py-3 mt-6 text-sm font-lufga tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#F2B807] rounded-md hover:bg-[#f5d77b] focus:outline-none focus:ring focus:ring-[#F2B807] focus:ring-opacity-50">
                            get in touch
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
</div>

            </section>
            <Footer />
        </main>
    );
}

export default ContactUs;