import React, { useState, useEffect } from "react";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
} from "react-circular-progressbar";
import { buildStyles } from "react-circular-progressbar/dist";

const LoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [loanTermYears, setLoanTermYears] = useState("");
  const [loanTermMonths, setLoanTermMonths] = useState("");
  const [annualInterestRate, setAnnualInterestRate] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [showResult, setShowResult] = useState(false);

  const calculateLoan = (e) => {
    e.preventDefault();

    const totalLoanAmount = parseFloat(loanAmount) - parseFloat(downPayment);
    const totalMonths =
      parseFloat(loanTermYears);
    const monthlyInterestRate = 0.12 / 12; // 12% annual interest rate divided by 12 months

    const numerator =
      monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalMonths);
    const denominator = Math.pow(1 + monthlyInterestRate, totalMonths) - 1;
    const monthlyPaymentValue = (
      totalLoanAmount *
      (numerator / denominator)
    ).toFixed(2);

    setMonthlyPayment(monthlyPaymentValue);
    setShowResult(true);
  };

  return (
    <>
    <section className='mx-2'>
        <div class="bg-white rounded-lg border shadow-lg shadow-[#efd27b] dark:bg-gray-800 md:mx-2 mb-8">
          <div class="container p-4 mx-auto">

          <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
        <h2 className="mb-4 text-[30px] font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide">
          Loan Calculator
        </h2>
        <p className="mb-5 text-center font-bold text-[#707070] sm:text-[18px] lg:text-xl dark:text-gray-400 font-lufga">
          Jenga Nyumba Loan Calculator
        </p>
      </div>

      <section class="max-w-4xl p-6 mx-auto bg-[#f0cd63] rounded-md shadow-md dark:bg-gray-800 mb-8">

        <form onClick={calculateLoan}>
          <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <label class="text-sm font-semibold text-gray-700 capitalize dark:text-white" for="username">Loan Amount (KES)</label>
              <input
                id="loanAmount"
                value={loanAmount}
                onChange={(e) => setLoanAmount(e.target.value)}
                required
                type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#f0cd63] focus:ring-[#f0cd63] focus:ring-opacity-40 dark:focus:border-[#f0cd63] focus:outline-none focus:ring" />
            </div>

            <div>
              <label class="text-sm font-semibold text-gray-700 capitalize dark:text-white" for="username">Down Payment (KES)</label>
              <input
                id="downpayment"
                value={downPayment}
                onChange={(e) => setDownPayment(e.target.value)}
                required
                type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#f0cd63] focus:ring-[#f0cd63] focus:ring-opacity-40 dark:focus:border-[#f0cd63] focus:outline-none focus:ring" />
            </div>

            <div>
              <label class="text-sm font-semibold text-gray-700 capitalize dark:text-white" for="username">Loan Term (Months)</label>
              <input
                id="loanTermYears"
                value={loanTermYears}
                onChange={(e) => setLoanTermYears(e.target.value)}
                required
                min={1}
                type="number" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#f0cd63] focus:ring-[#f0cd63] focus:ring-opacity-40 dark:focus:border-[#f0cd63] focus:outline-none focus:ring" />
            </div>

            <div>
              <label class="text-sm font-semibold text-gray-700 capitalize dark:text-white" for="username">Annual Interest Rate (%)</label>
              <input
                id="annualInterestRate"
                value="12%"
                readOnly
                type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-gray-300 border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#f0cd63] focus:ring-[#f0cd63] focus:ring-opacity-40 dark:focus:border-[#f0cd63] focus:outline-none focus:ring" />
            </div>
          </div>

          <div class="flex justify-end mt-6">
            <button class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Calculate</button>
          </div>
        </form>
      </section>

      {showResult && (
        <section class="max-w-4xl p-6 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
          {parseFloat(monthlyPayment) > 0 && (

            <>
              <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
                <p className="mb-5 text-center font-bold text-[#707070] md:text-[18px] lg:text-xl dark:text-gray-400 font-lufga">
                  Loan Summary
                </p>
              </div>
              <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                <div>
                  <div class="ml-4 w-56">
                    <p class="text-slate-500">Loan Amount</p>
                    <p class="text-slate-800 text-xl font-lufgaBold"><u>KES {parseFloat(loanAmount).toLocaleString()}</u></p>
                  </div>
                </div>

                <div>
                  <div className="circular-progress h-48 w-48">
                    <CircularProgressbarWithChildren
                      value={80}
                      //text={`${monthlyPayment}%`}
                      strokeWidth={8}
                      styles={buildStyles({
                        textColor: "black",
                        pathColor: "#F2B807",
                        trailColor: "#f5efd0",
                        strokeLinecap: "round",
                      })}
                    >
                      <div className="flex flex-col items-center justify-center h-full">
                        <p className="text-center">
                          Estimated monthly payment: <br />
                          <span>
                            <strong>
                              KES {parseFloat(monthlyPayment).toLocaleString()}
                            </strong>
                          </span>
                        </p>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>


              </div></>
          )}

        </section>
      )}

          </div>
        </div>

      </section></>
  );
};

export default LoanCalculator;
