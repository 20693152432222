import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import image1 from "../assets/account/Login_Image-min.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config";
import Cookies from "js-cookie";

const OTP = () => {
    //const [username, setUsername] = useState('');
    const [otp, setOtp] = useState('');
    const username = localStorage.getItem("username");
    const [loggedIn, setLoggedIn] = useState(false);
    axios.defaults.withCredentials = true;
    let navigate = useNavigate();


    // useEffect(() => {
    //   const token = Cookies.get('token');
    //   const checkAuth = async () => {
    //    if(token){
    //     setLoggedIn(true);
    //     navigate('/test-admin')
    //    }else{
    //     setLoggedIn(false);
    //     navigate('/login')
    //    }
    //   };

    //   checkAuth();
    // }, [navigate]);

      const handleOtp = async (e) => {
        e.preventDefault();
        try {
          await axios
            .post(`${BASE_URL}/api/verifyOTP`, { username, otp })
            .then((res) => {
              if (res.data.Status === "Success") {
                localStorage.setItem("token", res.data.token);
                navigate("/dashboard");
              } else {
                alert(res.data.Message);
              }
            })
            .catch((err) => console.log(err));
        } catch (error) {
          console.error("Login failed:", error);
        }
      };

    const sectionStyle = {
        backgroundImage: `url(${image1})`,
    };
    return (

        <main>
            <Header />
            <section class="bg-white dark:bg-gray-900 lg:mx-14 sm:mx-8 my-28">
            <div class="bg-white dark:bg-gray-900">
    <div class="flex justify-center h-[500px] rounded-[20px] border shadow-lg shadow-[#efd27b] mx-8">


        <div class="flex items-center w-full max-w-md px-8 md:px-2 mx-auto lg:w-2/6">
            <div class="flex-1">

                <div class="mt-8 lg:-ml-16">

                    <form onSubmit={handleOtp}>
                    <div><h3 for="email" class="block mb-2 text-4xl text-[#F2B807] font-lufgaBold dark:text-gray-200">Log In</h3></div>
                        <div>
                            <label for="email" class="block mb-2 text-lg text-gray-500 font-lufgaBold dark:text-gray-200">Enter the 6 digit code sent to your email</label>
                            <input
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                             type="text" name="email" id="email" placeholder="Enter the 6 digit Code" class="block w-full border-gray-100 px-4 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border text-sm  rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>


                        <div class="mt-8 md:flex md:items-center">
                        <button type="submit"  className="flex items-center rounded-lg bg-[#F2B807] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">
                            Submit
                        </button>

                        <p class="text-sm inline-block mt-4 text-center  md:mt-0 md:mx-6 text-gray-400">Don&#x27;t have an account? <a href="#" class="text-[#F2B807] focus:outline-none font-lufgaBold focus:underline hover:underline">Register</a> with promitto today.</p>
                    </div>

                    </form>


                </div>
            </div>
        </div>

        <div class="hidden bg-cover lg:block lg:w-1/2  rounded-[20px] rounded-bl-none rounded-tl-none border" style={sectionStyle}>
            <div class="flex items-center h-full px-20">
                <div>
                    {/* <h2 class="text-2xl font-bold text-white sm:text-3xl">Meraki UI</h2> */}
                     {/* <img src={image1} alt="" class="h-[400px]" /> */}
                </div>
            </div>
        </div>
    </div>
</div>

            </section>
            <Footer />
        </main>
    );
}

export default OTP;