import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const NoPage = () => {
    return (
        <main>
        <Header />
          <div className="my-[20%] items-center text-center text-4xl">
            <h1>Page Not Found!</h1>
          </div>
        <Footer />
      </main>
    )
}

export default NoPage;