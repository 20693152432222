import React from "react";
import { Link } from 'react-router-dom';

import james from "../../assets/about-us/James.png";
import peter from "../../assets/about-us/Peter_Kimani.png";
import hesbon from "../../assets/about-us/Hesbon_Kiura.png";
import francis from "../../assets/about-us/IMG_6707.png";
import adreen from "../../assets/about-us/adreen.png";
import linda from "../../assets/about-us/linda.png";
import innocent from "../../assets/about-us/innocent.png";
import tasha from "../../assets/about-us/tasha.png";
import john from "../../assets/about-us/john.png";

import ongeri from "../../assets/about-us/Innocent (1).png";
import eliza from "../../assets/about-us/eliza.png";
import sheila from "../../assets/about-us/Sheila_Karimi.png";
import joseph from "../../assets/about-us/Joseph.png";

const OurTeam = () => {

  const memberData = [
    { id: 1, name: 'James Kagoni', title: 'Chief Executive Officer', photo: james },
    //{ id: 2, name: 'Hesbon Kiura', title: 'Chairman of the board', photo: hesbon },
    { id: 3, name: 'Peter Kimani', title: 'Advocate of the High Court', photo: peter }
    // Add more team members as needed
  ];
  return (
    <section class="max-w-full bg-white bg-opacity-50 dark:bg-gray-900 border border-x-0 border-gray-200 shadow dark:border-gray-600 xl:p-8 dark:text-white">
      <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
        <h2
          className="mb-4 text-[40px] font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide"
          data-aos="fade-down"
        >
          Our Team.
        </h2>
        <p
          className="mb-5 text-center text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga"
          data-aos="fade-up"
        >
          At Promitto Ltd, our success is driven by our exceptional team of
          professionals. From our talented architects and designers who bring
          visionary concepts to life, to our dedicated project managers who
          ensure seamless execution, every member of our team plays a vital role
          in delivering exceptional results.
        </p>
        <p className="mt-4 text-lg font-lufga text-[#212121] font-bold">
          Board Members
        </p>
      </div>

      <div className="max-w-screen-xl -px-16 mx-auto lg:-px-16">
        <div className="space-y-2 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
          {memberData.map((member) => (
            <div key={member.id} className="flex relative overflow-hidden rounded-[20px]">
              <img src={member.photo} alt="image" className="w-full" />
              <div className="absolute bottom-5 left-0 w-full text-center">
                <div className="relative mx-5 overflow-hidden rounded-[15px] bg-white py-2 px-2">
                  <h4 className="text-[#F2B807] text-lg font-lufgaBold">
                    {member.name}
                  </h4>
                  <p className="font-lufgaBold text-[#010440] text-[15px]">
                    {member.title}
                  </p>

                  {/* Use the member ID to construct the link */}
                  <Link
                    to={`/about-team/${member.id}`}
                    className="text-[12px] text-[#5386FC] dark:text-[#5386FC] font-lufga underline hover:underline"
                  >
                    More about me
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>




    </section>
  );
};

export default OurTeam;
