import React, { useEffect } from 'react';

import { GiTakeMyMoney } from 'react-icons/gi';
import { FaUsers } from 'react-icons/fa';
import {MdOutlineEngineering} from 'react-icons/md';
import {BsBarChartSteps} from 'react-icons/bs';
import AOS from 'aos';
const Services = () => {
    useEffect(() => {
        AOS.init({ duration: 2000});
    },[]);
    return (
        <section class="bg-white dark:bg-gray-900">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
            <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <h2 className="mb-4 text-4xl font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide" data-aos="fade-down">Our Services.</h2>
                    <p className="mb-5 text-center text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga" data-aos="fade-up">
                        At Promitto Ltd, we understand that finding the perfect home is not just about bricks and mortar, it's about creating a space where cherished memories are made. That's why we strive to be the preferred choice for customers seeking their dream homes in Kenya.
                    </p>

            </div>
            <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0" data-aos="zoom-in">
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">

                    <div className="flex items-left justify-left mb-4">
                        <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                        <GiTakeMyMoney className="text-black text-2xl" />
                        </div>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold font-lufga">Construction Financing</h3>
                    <p class=" text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
                    We provide affordable and sustainable Jenga Nyumba Loan Product geared towards providing affordable housing.</p>

                   </div>
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-[#F2B807] border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">

                    <div className="flex items-left justify-left mb-4">
                        <div className="w-16 h-16 bg-black rounded-full flex items-center justify-center">
                        <MdOutlineEngineering className="text-[#F2B807] text-2xl" />
                        </div>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold font-lufga">Project Management</h3>
                    <p class="text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
                    We oversee everything about our clients project from site visit, construction monitoring, approvals amongst others.
                    </p>

                   </div>
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">

                    <div className="flex items-left justify-left mb-4">
                        <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                        <BsBarChartSteps className="text-black text-2xl" />
                        </div>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold font-lufga">Design Consultancy</h3>
                    <p class="text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
                    We offer site analysis, conceptual and schematic design, design development, overseeing of interior design, cost estimation, and creation of a detailed Bill of Quantities
                    </p>

                   </div>
            </div>
        </div>
      </section>
    );
};

export default Services;