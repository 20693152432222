import React, { useEffect } from "react";

import { GiTakeMyMoney } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { GiCornerFlag } from "react-icons/gi";
import { BsArrowRightCircle } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';

import mission from '../../assets/about-us//josue-isai-ramos-figueroa-qvBYnMuNJ9A-unsplash.png';
import image1 from '../../assets/how-to-own/Path 14.png';

import AOS from "aos";
const TermsConditions = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section class="bg-white dark:bg-gray-900 lg:mx-14 sm:mx-8 mt-8">
      <div class="max-w-screen-xl px-2 py-4 mx-auto lg:py-2 lg:px-2">
      <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <h2 className="mb-4 text-4xl font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide" data-aos="fade-down">
                    Our Terms & Conditions
                        </h2>

            </div>
        <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-2 lg:space-y-0">
          {/* <!-- Pricing Card 1--> */}
          <div class="flex flex-col max-w-[650px] max-h-full p-6 text-left text-gray-900 bg-white ">
             <div className="lg:pt-8 sm:pt-8">
             <h2 className="mb-4 text-3xl text-[#F2B807] dark:text-white font-lufgaBold tracking-wide" data-aos="fade-down">
             01. Loan Security.
                        </h2>
            <p class=" text-[#010440] sm:text-[16px] mb-4 dark:text-gray-600 font-lufgaBold mr-8">
            Borrower agrees that on or before the date of this agreement shall deliver the following document to the lender;
            </p>


            <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
                <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                    <li className="text-sm font-lufga text-gray-700 font-semibold">A title deed to a parcel of land upon which the construction project will be executed.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">The borrower must possess apersonal title deed written under his or her name.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">In case of the title deed belonging to a second party, the company will require written consent from the second party to use the title deed.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">The title deed must be free from any distress. Title deeds held up with other financing institutions shall not be accepted.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">If there are any family disputes concerning the title deed, Promitto will not be part and parcel of that. If in such a case payments had been made and the project had kick start, Payments made will be considered utilised and thus if the client will have a second location, they will have to make a new payment for the exercise to be conducted.</li>
                </ul>
            </li>
            </ol>

             </div>

          </div>
            {/* <!-- Pricing Card 2--> */}
            <div class="flex flex-col max-w-[800px] max-h-full p-6 text-left text-gray-900 bg-white">
             <div className="lg:pt-8 sm:pt-8 -m-4">
                         <img
                alt="gallery"
                class="h-75 w-full rounded-[20px] object-cover object-center"
                src={image1} />

             </div>

          </div>

        </div>



      </div>

      <div class="bg-[#F9F9F9D9] shadow dark:border-gray-600 dark:bg-gray-800 border rounded-[20px] border-gray-200 lg:mx-2 mb-8">
                    <div class="container p-6 sm:mx-6 mx-auto">

        <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-2 lg:space-y-0">
          {/* <!-- Pricing Card 1--> */}
          <div class="flex flex-col max-w-[650px] max-h-full p-6 text-left text-gray-900">
             <div className="lg:pt-8 sm:pt-8">
             <h2 className="mb-4 lg:text-3xl sm:text-8xl text-[#F2B807] dark:text-white font-lufgaBold tracking-wide" data-aos="fade-down">
             02. Loan Terms.
                        </h2>
            <p class=" text-[#010440] sm:text-[22px] mb-4 dark:text-gray-600 font-lufgaBold mr-8">
            (A).Deposits
            </p>


            <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
                <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                    <li className="text-sm font-lufga text-gray-700 font-semibold">The borrower shall be required to make a 30% deposit of the loan amount depending on the selected house design.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">In cases where the borrower is not financially ready with the minimum deposit for the construction to happen, the client will be allowed to make instalments payments but will be made to understand that the construction will only start once the minimum deposit has been attained.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">In a case where the client/borrower is ready with the 30% deposit, they will proceed to sign the construction agreement for the construction to commence.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">The client can also deposit more than the required 30% minimum deposit to kickstart the project if they chose to do so.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">The Company can also enter into a construction agreement with a client not seeking a loan facility for
                    construction on the condition that the client provides the full finances to undertake the project.</li>

                </ul>
            </li>
            </ol>
            <p class=" text-[#010440] sm:text-[22px] mb-4 mt-4 dark:text-gray-600 font-lufgaBold mr-8">
            (C).Expenses
            </p>
            <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
                <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                    <li className="text-sm font-lufga text-gray-700 font-semibold">In case the client changes any component of the construction along the way, QS will have to re-evaluate
                the change cost and charge the customer. This will have to be paid for immediately.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">In case a statutory requirement is delayed, Promitto shall not be held liable.</li>

                </ul>
            </li>
            </ol>

             </div>

          </div>
            {/* <!-- Pricing Card 2--> */}
            <div class="flex flex-col max-w-[650px] max-h-full p-6 text-left text-gray-900">
             <div className="lg:pt-8 sm:pt-8">

             <p class=" text-[#010440] sm:text-[22px] mb-4 lg:mt-8 sm:mt-2 dark:text-gray-600 font-lufgaBold mr-8">
             (B).Loan Repayment Term
            </p>


            <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
                <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                    <li className="text-sm font-lufga text-gray-700 font-semibold">The Loan shall be payable up to a maximum of seven (7) years. The exact loan term shall be as discussed and signed in the Loan application form.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">The loan interest rate for the construction loan will be at 12% on a reducing balance rate. With a monthly standard payment plan which the borrower is expected to honour without fail.</li>
                  </ul>
            </li>
            </ol>

            <p class=" text-[#F2B807] sm:text-lg mb-4 mt-4 dark:text-gray-600 font-lufgaBold mr-8">
            A customer can default a loan in three ways:
            </p>
            <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
                <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                    <li className="text-sm font-lufga text-gray-700 font-semibold">If the client fails to completely repay the remaining 70% of the loan</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">If the client fails to repay the monthly instalment as agreed.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">If the client fails to repay the monthly instalment on the stipulated timeline.</li>
                </ul>
            </li>
            </ol>
            <p class=" text-[#F2B807] sm:text-lg mb-4 mt-4 dark:text-gray-600 font-lufgaBold mr-8">
            Actionables
            </p>
            <p class=" text-gray-600 sm:text-sm mb-4 mt-4 dark:text-gray-600 font-lufga mr-8">
            <i>If the client fails to repay any instalment  within 60 days, the directive will be to</i>
            </p>
            <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
                <ul class="pl-5 space-y-1 list-disc list-outside">
                    <li className="text-sm font-lufga text-gray-700 font-semibold">Repossess or auction the property.</li>
                    <li className="text-sm font-lufga text-gray-700 font-semibold">If the client fails to repay the loan on the agreed instalments or on the agreed timelines there will be added penalties incurred.</li>

                </ul>
            </li>
            </ol>

             </div>

          </div>

        </div>
            </div>
                </div>
    </section>
  );
};

export default TermsConditions;
