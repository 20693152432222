import React, { useEffect, useState } from "react";
import AOS from "aos";
import ModalImage from "react-modal-image";

import { BASE_URL } from "../../config";

const HousingOngoingProjects = () => {
  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [visible, setVisible] = useState(8);

  const showMoreProjects = () => {
    setVisible((prevValue) => prevValue + 8);
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    fetch(`${BASE_URL}/api/getOngoingProjects`)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          console.log(data.Projects);
          setOngoingProjects(data.Projects);
        } else {
          // Handle the error here
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <section className="max-w-full bg-white bg-opacity-50 dark:bg-gray-900 xl:p-8 dark:text-white">
      <div className="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
        <h2
          className="mb-4 text-[40px] font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide"
          data-aos="fade-down"
        >
          Our ongoing projects.
        </h2>
        <p
          className="mb-5 text-center text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga"
          data-aos="fade-up"
        >
          Some of our ongoing projects underway at various stages.
        </p>
      </div>

      <div className="max-w-screen-xl -px-16 mx-auto lg:-px-16">
        <div className="space-y-2 grid lg:grid-cols-4 xs:grid-cols-2 sm:gap-6 xs:gap-2 xl:gap-10 lg:space-y-0">
          {!ongoingProjects ? (
            "No data found"
          ) : (
            ongoingProjects.slice(0, visible).map((project) => (
              <div className="xs:mx-2 md:mx-2">
                <div className="flex relative overflow-hidden rounded-[20px]">
                  <div className="w-full h-[300px]">
                    <img
                      src={project.project_image_url}
                      alt="image"
                      className="w-full h-full object-cover max-w-xs transition duration-300 ease-in-out hover:scale-110"
                    />
                  </div>
                </div>
                <div className="items-left text-left ml-2">
                  <p className="font-lufgaBold text-sm">{project.description}</p>
                  <p className="font-lufga text-xs -mt-1">{project.Location}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12 mt-12">
        <button
          onClick={showMoreProjects}
          className="inline-flex items-center font-lufga bg-[#F2B807] justify-center w-full px-5 py-3 text-sm font-bold text-center text-white rounded-sm sm:w-auto  focus:ring-4 focus:ring-gray-100 dark:text-white"
        >
          More ongoing Projects
        </button>
      </div>
    </section>
  );
};

export default HousingOngoingProjects;
