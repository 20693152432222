

import React, { useState, useRef, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import {useNavigate} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { BASE_URL } from "../config";
import { Modal, Ripple, initTE } from "tw-elements";

const TestRegister = () => {
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ID, setID] = useState("");
  const navigate = useNavigate();
  const [projectInfo, setProjectInfo] = useState([]);
  const [numberOfPhotos, setnumberOfPhotos] = useState([]);


  const [selectedKraFile, setSelectedKraFile] = useState(null);
  const [selectedIDFile, setSelectedIDFile] = useState(null);
  const [selectedTitleDeedFile, setSelectedTitleDeedFile] = useState(null);
  const [selectedPassportPhotoFile, setSelectedPassportPhotoFile] = useState(null);

  const [showDefaultButton, setShowDefaultButton] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(false);


  useEffect(() =>{
    initTE({ Modal, Ripple });
  })
  const handleIDFileChange = (event) => {
    setSelectedIDFile(event.target.files[0]);
  };

  const handleKraFileChange = (event) => {
    setSelectedKraFile(event.target.files[0]);
  };
  const handleTitleDeedFileChange = (event) => {
    setSelectedTitleDeedFile(event.target.files[0]);
  };

  const handlePassportPhotoFileChange = (event) => {
    setSelectedPassportPhotoFile(event.target.files[0]);
  };

  const clearForm = () => {
    // Reset the form fields by setting their values to empty strings or null
    setName("");
    setEmail("");
    setID("");
    setSelectedKraFile(null);
    setSelectedIDFile(null);
    setSelectedTitleDeedFile(null);
    setSelectedPassportPhotoFile(null);
  };

  const openModal = () => {
    // Use JavaScript to toggle the modal's visibility
    const modal = document.getElementById("exampleModalCenter");
    modal.style.display = "block";
  };

    const handleSubmit = async (event) => {
      event.preventDefault();

      // Prepare the data to be sent to the server
      const userData = {
        name: name,
        email: email,
      };

      try {
        const response = await fetch(`${BASE_URL}/api/test-register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data.ID);
          setID(data.ID);
          setShowUploadButton(true);
          alert(data.Message);
           } else {
          // Handle errors, e.g., display an error message to the user
          alert('Registration failed');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const uploadDocuments = async () => {

      const formData = new FormData();
      formData.append("image", selectedPassportPhotoFile);
      formData.append("image", selectedIDFile);
      formData.append("image", selectedKraFile);
      formData.append("image", selectedTitleDeedFile);

      try {
        const response = await fetch(`${BASE_URL}/api/register-documents/${ID}`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          clearForm();
          alert(data.Message);
        } else {
          console.log("Error Updating");
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };




  return (
    <main>
      <Header />
      <section class="lg:mx-14 mt-20">
        <div class="mx-auto mr-8 mt-8 max-w-screen-xl overflow-hidden md:pl-8 bg-white">
          <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
            <h2 className="mb-4 text-4xl text-[#F2B807] dark:text-white font-lufgaBold tracking-wide">
              Create an Account
            </h2>
            <p className="-mb-8 text-center font-bold text-[#707070] sm:text-[16px] lg:text-lg dark:text-gray-400 font-lufga">
              Become a home owner today by Enrolling as a member.

            </p>

          </div>
        </div>

        <div class="mx-auto my-8 max-w-screen-xl overflow-hidden md:pl-8 bg-[#FFFCF2]">
          {/* Title Section */}
          <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">

            <h2 className="mb-4 text-[30px] font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide">
              How you can Register?
            </h2>
            <p className="mb-5 text-center font-bold text-[#707070] sm:text-[18px] lg:text-[16px] dark:text-gray-400 font-lufga">
              There are 3 main ways a new member will be able to express their
              interest to <br /> join Promitto Homes
            </p>
          </div>
          <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 mx-8 mb-8">
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-right"
            >
              <h3 class="mt-6 text-6xl text-[#F2B807] font-lufgaBold">01</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Using your phone send a text to 0729 509 509 via WhatsApp.
              </p>
            </div>
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-[#F2B807] border rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-up"
            >
              <h3 class="mt-6 text-6xl text-[#010440] font-lufgaBold">02</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Log in to Promitto Website and go to new member page and follow
                the instructions.
              </p>
            </div>
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-left"
            >
              <h3 class="mt-6 text-6xl text-[#F2B807] font-lufgaBold">03</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Visit Promitto Office and follow the guidance from customer
                Service Attendant.
              </p>
            </div>
          </div>
        </div>

        <div className="mx-20">
        <form className="mt-10" onSubmit={handleSubmit}>
                  <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                          Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        id="name" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Email. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email" type="email"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                  </div>
                  <button type="submit" className="flex items-center rounded-lg bg-[#F2B807] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">
                            Submit
                        </button>
                </form>
                {showUploadButton && (
                  <div
                  class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                  <div
                    class="border-b-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
                    Quote
                  </div>
                  <div class="p-6">
                  <form onSubmit={uploadDocuments}>
        <div class="relative p-4">
      <div className='mx-8'>
        <p class="mb-2 text-sm font-medium">Passport Photo Image</p>
        <input type="file" name="passportfile" className='' onChange={handlePassportPhotoFileChange} />
        <p class="mb-2 text-sm font-medium">National ID/ Passport</p>
        <input type="file" name="idfile" className='' onChange={handleIDFileChange} />
        <p class="mb-2 mt-4 text-sm font-medium">KRA PIN Certificate</p>
        <input type="file" name="krafile" onChange={handleKraFileChange} />
        <p class="mb-2 mt-4 text-sm font-medium">Title Deed Copy</p>
        <input type="file" name="titlefile" onChange={handleTitleDeedFileChange} />
        </div>
      </div>
      <div
        class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
        <button
          type="button"
          class="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
          data-te-modal-dismiss
          data-te-ripple-init
          data-te-ripple-color="light">
          Close
        </button>
        <button
          type="submit"
          class="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init
          data-te-ripple-color="light">
          Upload
        </button>
      </div>
        </form>
                  </div>
                </div>
                )}


        </div>

        <div
                          data-te-modal-init
                          class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                          id="exampleModalCenter1"
                          tabindex="-1"
                          aria-labelledby="exampleModalCenterTitle"
                          aria-modal="true"
                          role="dialog"
                        >
                          <div
                            data-te-modal-dialog-ref
                            class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
                          >
                            <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                              <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                {/* <!--Modal title--> */}
                                <h5
                                  class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                  id="exampleModalScrollableLabel"
                                >
                                  Please Upload these files to finish your
                                  Application
                                </h5>
                                {/* <!--Close button--> */}
                                <button
                                  type="button"
                                  class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                  data-te-modal-dismiss
                                  aria-label="Close"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="h-6 w-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                              {/* <!--Modal body--> */}
                              <form onClick={uploadDocuments}>
                              <div className='mx-8'>
                              <p class="mb-2 text-sm font-medium">Passport Photo Image</p>
                              <input type="file" name="passportfile" className='' onChange={handlePassportPhotoFileChange} />
                              <p class="mb-2 text-sm font-medium">National ID/ Passport</p>
                              <input type="file" name="idfile" className='' onChange={handleIDFileChange} />
                              <p class="mb-2 mt-4 text-sm font-medium">KRA PIN Certificate</p>
                              <input type="file" name="krafile" onChange={handleKraFileChange} />
                              <p class="mb-2 mt-4 text-sm font-medium">Title Deed Copy</p>
                              <input type="file" name="titlefile" onChange={handleTitleDeedFileChange} />
                              </div>


                              {/* <!--Modal footer--> */}
                              <div class="flex mr-12 flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

                                <button
                                  type="button"
                                  class="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                  data-te-modal-dismiss
                                  data-te-ripple-init
                                  data-te-ripple-color="light"
                                >
                                  Close
                                </button>
                                <button
                                type="submit"
                                  class="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                  data-te-ripple-init
                                  data-te-ripple-color="light"
                                >
                                  Submit
                                </button>

                              </div>
                              </form>
                            </div>
                          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default TestRegister;
