import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import { BASE_URL} from '../../../config';
import {BiEdit} from 'react-icons/bi';
import {MdOutlineAddAPhoto, MdSave} from 'react-icons/md';
import { TbHistoryOff } from "react-icons/tb";
import { FaHistory } from "react-icons/fa";
import FileUpload from "./file-upload.component";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SiteVisit = () => {
    const { id } = useParams();
    const [projectInfo, setProjectInfo] = useState([]);
    const [editSiteVisit, setEditSiteVisit] = useState([]);
    const [displayInfo, setDisplayInfo] = useState(false);

    const [inputFields, setInputFields] = useState([
      { site_visit_desc: '', site_visit_value: '' }
    ])

    const isButtonDisabled = inputFields.some(
      field => field.site_visit_desc === '' || field.site_visit_value === ''
    );

    const [uploadSuccess, setUploadSuccess] = useState(false);


const [newUserInfo, setNewUserInfo] = useState({
profileImages: []
});

const updateUploadedFiles = (files) =>
setNewUserInfo({ ...newUserInfo, profileImages: files });

const handleSubmit = async (event) => {
    event.preventDefault();

      // Check if there are no files to upload
    if (newUserInfo.profileImages.length === 0) {
        // Show an alert to the user
        alert("Please upload at least one file before submitting.");
        return; // Prevent further execution
    }

    try {
      // Create a FormData object to send files to the API
      const formData = new FormData();
      newUserInfo.profileImages.forEach((file) => {
        formData.append("siteVisitFiles", file);
      });

      // Send the FormData to your Node.js API
      const response = await fetch(`${BASE_URL}/api/uploadSiteVisitFiles/${id}`, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        // Handle success, e.g., show a success message
        setUploadSuccess(true);
        toast.success("Upload was successfull", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          });

      } else {
        // Handle errors, e.g., show an error message
        console.error("Failed to upload files.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
 }

 const addFields = (event) => {
  event.preventDefault();
  let newfield = { site_visit_desc: '', site_visit_value: '' }

  setInputFields([...inputFields, newfield])
}

const removeFields = (index) => {
  let data = [...inputFields];
  data.splice(index, 1)
  setInputFields(data)
}

const submit = (e) => {
    e.preventDefault();
    console.log(inputFields);
    //localStorage.setItem("data", inputFields);
    localStorage.setItem('site_visit', JSON.stringify(inputFields));
  }

 // Use useEffect to save inputFields to localStorage whenever it changes
 useEffect(() => {
  localStorage.setItem('site_visit', JSON.stringify(inputFields));
}, [inputFields]);

const clearFields = (e) => {
  e.preventDefault();
  setInputFields([{ site_visit_desc: '', site_visit_value: '' }]);
  localStorage.removeItem("site_visit");
}

const getProjectDetails = () => {
    fetch(`${BASE_URL}/api/getClientProjectByClientId/${id}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.Status === "Success") {

              if(data.ProjectDetails[0].site_visit != null){
                //console.log(data.ProjectDetails[0].site_visit);
                setDisplayInfo(true);
                setProjectInfo(data.ProjectDetails);
                setEditSiteVisit(JSON.parse(data.ProjectDetails[0].site_visit));
              }else{
                return;
              }

            } else {
             return;
            }
          })
          .catch((error) => console.log(error));
  }
  useEffect(() => {
      getProjectDetails();

      // const intervalId = setInterval(getProjectDetails, 2000);

      // // Clean up the interval when the component unmounts.
      // return () => clearInterval(intervalId);
  }, [id]);


const submitSiteVisitInfo = async (e) => {
    e.preventDefault();
    const siteVisit = localStorage.getItem('site_visit');
    const data = { siteVisit };
    console.log(data);
    await fetch(`${BASE_URL}/api/submitSiteVisitInfo/${id}`, {
      method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) =>{
      if (data.Status === "Success"){
        setDisplayInfo(true);
        localStorage.removeItem("site_visit");
        setInputFields([{ site_visit_desc: '', site_visit_value: '' }]);
        toast.success(data.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          });
      }else{
        alert(data.Status);
        console.log(data.Status[0])
      }
    })
  }
  const handleSiteVisitInputChange = (index, key, value) => {
    const updatedSiteVisitData = [...editSiteVisit];
    updatedSiteVisitData[index][key] = value;
    setEditSiteVisit(updatedSiteVisitData);
  };

const reloadDetails =() =>{
  getProjectDetails();
}

  //send the update
  const updateSiteVisit = async () => {
    // const kyc = {editKYCData};
    //console.log('Request Payload:', kyc);
    try {
      const response = await axios.put(`${BASE_URL}/api/editSiteVisitInfo/${id}`, {siteVisit: editSiteVisit});
      if(response.data.Status === 'Success'){
        toast.success(response.data.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          });
      }else{
        alert("An error occurred, try again")
      }
      // Handle success or update UI as needed
    } catch (error) {
      console.error(error.response.data);
      // Handle error or update UI as needed
    }
  };
    return (
      <>
        <div class="max-w-lg relative">
          <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
            {/* Card content */}
            <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
              Site Visit
            </h1>
            <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
              Please select the buttons below to upload the required data.
            </h3>
            <div className="max-w-screen-md mx-auto text-center mt-4">
              <div className="flex justify-center gap-12">
                {/* Buttons */}
                <button
                  data-te-toggle="modal"
                  data-te-target="#sitevisitfiles"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="flex items-center rounded-[5px] bg-[#bac4ed] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                >
                  <MdOutlineAddAPhoto className="ml-4 mr-2 text-sm" />{" "}
                  <span className="mr-8">Add File</span>
                </button>
                <button
                  data-te-toggle="modal"
                  data-te-target="#siteVisit"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="flex items-center rounded-[5px] bg-[#f5d77b] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                >
                  <BiEdit className="ml-4 mr-2 text-sm" />{" "}
                  <span className="mr-8">Add Text</span>
                </button>
              </div>

              {/* Icon in the top right */}

              <div className="absolute top-0 right-0 m-4">
              {!displayInfo ? (
                  <TbHistoryOff
                  className="text-xl text-gray-800" />
                  ):(
                    <FaHistory
                    onClick={reloadDetails}
                    data-te-toggle="modal"
                    data-te-target="#svh"
                    className="text-xl text-red-700 hover:cursor-pointer" />
                  )}

              </div>
            </div>
          </div>
        </div>
        <div
          data-te-modal-init
          class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
          id="siteVisit"
          tabindex="-1"
          aria-labelledby="exampleModalXlLabel"
          aria-modal="true"
          role="dialog"
        >
          <div
            data-te-modal-dialog-ref
            class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
          >
            <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
              <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                <h5
                  class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                  id="exampleModalXlLabel"
                >
                  Site Visit
                </h5>
                <button
                  type="button"
                  class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  data-te-modal-dismiss
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              {/* <!--Modal body--> */}
              <div class="relative p-4">
                <form onClick={submit}>
                  {inputFields.map((input, index) => {
                    return (
                      <>
                        <div key={index}>
                          <input
                            name="site_visit_desc"
                            placeholder="description"
                            className="border border-gray-500 mr-4 px-4 py-2 mt-2 text-gray-700 bg-white rounded-md"
                            value={input.site_visit_desc}
                            autoComplete="off"
                            onChange={(event) => handleFormChange(index, event)}
                            required
                          />
                          <input
                            name="site_visit_value"
                            placeholder="value"
                            className="border border-gray-500 mr-4 px-4 py-2 mt-2 text-gray-700 bg-white rounded-md"
                            value={input.site_visit_value}
                            autoComplete="off"
                            onChange={(event) => handleFormChange(index, event)}
                            required
                          />
                          {index != 0 && (
                            <button
                              className="bg-red-500 text-white py-1.5 px-4 ml-2 rounded"
                              onClick={() => removeFields(index)}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </>
                    );
                  })}
                  <button
                    className="bg-[#F2B807] text-white py-1.5 mr-2 px-4 rounded"
                    onClick={addFields}
                  >
                    Add More
                  </button>
                  <button
                    className="bg-red-500 text-white py-1.5 mr-2 px-4 rounded mt-4"
                    onClick={clearFields}
                  >
                    Reset
                  </button>

                  <button
                    disabled={isButtonDisabled}
                    onClick={submitSiteVisitInfo}
                    className={`py-1.5 px-4 rounded mt-4 ${
                      isButtonDisabled
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-green-500 hover:bg-green-600'
                    } text-white`}
                  >
                    Submit data
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          data-te-modal-init
          class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
          id="svh"
          tabindex="-1"
          aria-labelledby="exampleModalXlLabel"
          aria-modal="true"
          role="dialog"
        >
          <div
            data-te-modal-dialog-ref
            class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
          >
            <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
              <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                <h5
                  class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                  id="exampleModalXlLabel"
                >
                  Edit Site Visit Details History
                </h5>
                <button
                  type="button"
                  class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  data-te-modal-dismiss
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="relative p-4">
                <div className="container mx-auto p-4">
                  <form onSubmit={(e) => e.preventDefault()}>
                    {editSiteVisit.map((item, index) => (
                      <div key={index}>
                        <div className="grid gap-3 md:grid-cols-3">
                          <div className="flex items-center mt-2">
                            <label className="mr-2">Description:</label>
                            <input
                              type="text"
                              value={item.site_visit_desc}
                              onChange={(e) =>
                                handleSiteVisitInputChange(
                                  index,
                                  "site_visit_desc",
                                  e.target.value
                                )
                              }
                              id="small-input"
                              className="w-3/4 p-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 sm:text-xs dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            />
                          </div>

                          <div className="flex items-center mt-2 col-span-2">
                            <label className="mr-2">Description Value:</label>
                            <input
                              type="text"
                              value={item.site_visit_value}
                              onChange={(e) =>
                                handleSiteVisitInputChange(
                                  index,
                                  "site_visit_value",
                                  e.target.value
                                )
                              }
                              className="w-3/4 p-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 sm:text-xs dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                    onClick={updateSiteVisit}
                      class="flex items-center rounded-sm bg-gray-700 px-2 py-2 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
                    >
                      <MdSave className="text-gray-100 mr-2 text-lg" /> Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-te-modal-init
          class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
          id="sitevisitfiles"
          tabindex="-1"
          aria-labelledby="exampleModalXlLabel"
          aria-modal="true"
          role="dialog"
        >
          <div
            data-te-modal-dialog-ref
            class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
          >
            <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
              <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                <h5
                  class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                  id="exampleModalXlLabel"
                >
                  Upload Photos or PDF's
                </h5>
                <button
                  type="button"
                  class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  data-te-modal-dismiss
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              {/* <!--Modal body--> */}
              <div class="relative p-4">
                <form onSubmit={handleSubmit}>
                  <FileUpload
                    accept=".jpg,.png,.jpeg,.pdf"
                    label="Drawing Image(s)"
                    multiple
                    updateFilesCb={updateUploadedFiles}
                    resetOnSuccess={uploadSuccess}
                  />
                  <button
                    type="submit"
                    class="px-3 py-2 text-xs font-medium text-center text-black bg-[#F2B807] rounded-sm hover:bg-[#F2B807] focus:ring-4 focus:outline-none focus:ring-[#F2B807] dark:bg-[#F2B807] dark:hover:bg-[#F2B807] dark:focus:ring-[#F2B807]"
                  >
                    Upload Photos
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default SiteVisit;