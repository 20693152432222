import React, {useEffect} from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ChooseDreamHome from "../components/HousingProjects/ChooseDreamHome";
import CTA from "../components/HousingProjects/CTA";
import HousingOngoingProjects from "../components/HousingProjects/HousingOngoingProjects";

const HousingProjects = () => {

  return (
    <main>
      <Header />
      <div class="max-w-screen-md mx-auto items-center text-center mt-24">
        <h2
          className="mb-4 text-3xl font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide"

        >
          Choose Your Dream Home.
        </h2>
        <p className="mb-5 text-center text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga" data-aos="fade-up">
        Discover Your Dream Home in Kenya with Promitto Ltd.</p>
      </div>
      <ChooseDreamHome />
      <CTA />
      <HousingOngoingProjects />
      <Footer />
    </main>
  );
};

export default HousingProjects;
