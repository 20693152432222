import React, { useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { FaTwitterSquare, FaPinterest, FaFacebook, FaInstagram, FaYoutube, FaEnvelope, FaTiktok, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { BASE_URL} from '../config';
import { toast } from 'react-toastify';

const Testimonials = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const handleNameChange = (e) =>{
        setFullName(e.target.value);
      }
      const handleEmailChange = (e) =>{
        setEmail(e.target.value);
      }
      const handlePhoneChange = (e) =>{
        setPhoneNumber(e.target.value);
      }
      const handleMessageChange = (e) =>{
        setMessage(e.target.value);
      }
      const handleSubjectChange = (e) =>{
        setSubject(e.target.value);
      }

      const SendEmail = async (e) => {
        e.preventDefault();
        const data = { fullName, email, phoneNumber, subject, message };
        await fetch(`${BASE_URL}/api/send-contact-email`, {
        method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.Status === "Success"){
                setFullName('');
                setEmail('');
                setPhoneNumber('');
                setSubject('');
                setMessage('');
                toast.success(data.Message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });

            }else{
                alert("Failed to send email. Try again");
            }
        })
        }
    return (

        <main>
            <Header />
            <section className="bg-white dark:bg-gray-900 lg:mx-14 sm:mx-8 my-28">
            <div className="bg-white dark:bg-gray-900">
            <section className="bg-white dark:bg-gray-900 border-[3px] border-[#f5d77b] rounded-[20px]">
    <div className="container px-6 py-12 mx-auto">
        <h2>Testimonials Page</h2>
    </div>
</section>
</div>

            </section>
            <Footer />
        </main>
    );
}

export default Testimonials;