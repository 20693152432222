import React, {useEffect} from 'react';
import AOS from 'aos';
import { FaUserTie } from 'react-icons/fa';
import { BiTrendingUp } from 'react-icons/bi';
import { FaHandHoldingHeart } from 'react-icons/fa';
const WhyChooseUs = () => {
    useEffect(() => {
        AOS.init({ duration: 2000});
    },[]);
    return (
        <section class="bg-white dark:bg-gray-900">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
            <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <h2 className="mb-4 text-4xl font-bold tracking-wide text-[#F2B807] dark:text-white font-lufga" data-aos="fade-down">Why Choose Us</h2>
                    <p className="mb-5 text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga" data-aos="fade-up">
                    With our unwavering commitment to excellence, extensive industry expertise, and a vast portfolio of exceptional properties, we offer an unparalleled experience that sets us apart.
                        </p>

            </div>
            <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-right">

                    <div className="flex items-left justify-left mb-4">
                        <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                        <FaHandHoldingHeart className="text-black text-2xl" />
                        </div>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold font-lufga">Due Care</h3>
                    <p class="text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
                    We exercise utmost care in all our dealings with members, employees and other associated stakeholders to produce best value for our products.</p>

                   </div>
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-up">

                    <div className="flex items-left justify-left mb-4">
                        <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                        <FaUserTie className="text-black text-2xl" />
                        </div>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold font-lufga">Professionalism</h3>
                    <p class="text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
                    We continually develop our employees to ensure high level of work ethic and excellence in our conduct and business with members.
                    </p>

                   </div>
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-left">

                    <div className="flex items-left justify-left mb-4">
                        <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                        <BiTrendingUp className="text-black text-2xl" />
                        </div>
                    </div>
                    <h3 class="mb-4 text-xl font-semibold font-lufga">Efficiency</h3>
                    <p class="text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">
                    We believe in creating best value using minimal resources and costs in a bid to promote sustainability in all our processes.
                   </p>

                   </div>
            </div>
        </div>
      </section>
    );
};

export default WhyChooseUs;