import React from "react";

//import "../App.css";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MainContent from "../components/MainContent/MainContent";
import Services from "../components/Sections/Services";
import WhyChooseUs from "../components/Sections/WhyChooseUs";
import Testimonials from "../components/Sections/Testimonials";
import Section from "../components/Sections/Section";
import HomeSelection from "../components/Sections/HomeSelect";
import Statistics from "../components/Sections/Statistics";
import Explore from "../components/Sections/Explore";

const Home = () => {
  return (
    <main>
      <Header />
      <MainContent />
      <Statistics />
      <Explore />
      <HomeSelection />
      <Services />
      <WhyChooseUs />
      <Section />
      <Testimonials />
      <Footer />
    </main>
  );
};

export default Home;
